import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import countryApi from 'api/country'

export const countrySlice = createSlice({
    name: 'country',
    initialState: {
        list: []
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getListCountry.fulfilled, (state, action) => {
                state.list = action.payload
            })

    },
})

export const getListCountry = createAsyncThunk(
    'country/getListCountry',
    async (payload, { dispatch }) => {
        const res = await countryApi.getAll()
        return res
    }
)

export const selectListCountry = (state) => state.country.list

export default countrySlice.reducer
