import { Avatar, Button, Col, Form, Image, Input, Row, Space, Table, Upload } from "antd";
import classNames from "classnames/bind";
import { VALIDATE_IMAGE } from "constants/";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getUser } from "utils/";
import style from "../index.module.scss";
import img from 'assets/images/null-img.png'
import { columns } from "./columns";
// import { handleUploadImage } from "utils/";
// import Context from "layout/private/Context";
import { useDispatch, useSelector } from "react-redux";
import { checkPhoneNumber } from "utils/";
import { selectListRole, updateInfo } from "features/users/userSlice";
import userApi from "api/user";
import { setUser } from "utils/";
import imageApi from "api/image";

const cx = classNames.bind(style);

export function AccountDetail() {
    const [form] = Form.useForm()

    const dispatch = useDispatch()

    const listRole = useSelector(selectListRole)

    const [loading, setLoading] = useState(false)
    const [fileList, setFileList] = useState([]);
    const [imageProfile, setImageProfile] = useState('')
    const [isEdit, setIsEdit] = useState(false);
    const [currentInfo, setCurrentInfo] = useState(getUser())
    const [userInfo, setUserInfo] = useState(null);

    const toggleMode = () => {
        if (!isEdit) {
            if (imageProfile) setFileList([{
                uid: '-1',
                name: '',
                status: 'done',
                url: imageProfile
            }]);
            else setFileList([])
            form.setFieldsValue({
                email: currentInfo.email,
                name: currentInfo.name,
                phoneNumber: currentInfo.phoneNumber
            })
        }
        setIsEdit(!isEdit);
    }

    const beforeUpload = (file) => {
        const isAccept = VALIDATE_IMAGE.accept.includes(file.type);
        const overLimit = file.size / 1024 / 1024 <= process.env.REACT_APP_MAX_FILE_SIZE;
        if (!isAccept || !overLimit) {
            return false;
        }

        return true;
    };

    const onChange = ({ fileList: newFileList }) => {
        if (newFileList.length > 0 && newFileList[0].status === "error") {
            newFileList[0].status = "done";
        }

        setFileList(newFileList);
    };

    const onPreview = async (file) => {
        let src = file.url;

        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);

                reader.onload = () => resolve(reader.result);
            });
        }

        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const onRemove = (file) => {
        setFileList([]);
    }

    const cancelSubmit = () => {
        setFileList([])
        setIsEdit(false);
    }

    const onFinish = async (values) => {
        try {
            setLoading(true)
            const body = values
            if (fileList.length > 0) {
                if (+fileList[0].uid !== -1) {
                    const res = await imageApi.upload(fileList[0].originFileObj)
                    body.img = res.replace(/\n/g, '')
                    currentInfo?.img && await imageApi.delete(currentInfo.img)
                }
            } else {
                currentInfo?.img && await imageApi.delete(currentInfo.img)
                body.img = ''

            }
            await userApi.changeInfo(body)
            const newInfo = {
                ...currentInfo,
                ...body
            }
            setUser(newInfo)
            setCurrentInfo(newInfo)
            dispatch(updateInfo(newInfo))
            setIsEdit(false);
            toast.success("Cập nhật tài khoản thành công");
        } catch (error) {
            toast.error(error?.response?.data);
        } finally {
            setLoading(false)
        }
    }

    const fetchImageProfile = async () => {
        if (currentInfo?.img) {
            setImageProfile(process.env.REACT_APP_API_URL + process.env.REACT_APP_IMAGE_PATH + currentInfo.img)
        } else {
            setImageProfile('')
        }
    }

    const setUserInfoManual = () => {
        setUserInfo([
            {
                key: 'name',
                label: 'Tên',
                value: currentInfo?.name
            },
            {
                key: 'username',
                label: 'Username',
                value: currentInfo?.username
            },
            {
                key: 'email',
                label: 'Email',
                value: currentInfo?.email
            },
            {
                key: 'phoneNumber',
                label: 'SĐT',
                value: currentInfo?.phoneNumber
            },
            {
                key: 'roleId',
                label: 'Chức vụ',
                value: currentInfo?.role?.name
            },
            {
                key: 'teamName',
                label: 'Tên nhóm',
                value: currentInfo?.team?.name
            },
        ])
    }

    useEffect(() => {
        fetchImageProfile()
        setUserInfoManual()
    }, [listRole, currentInfo])

    return (
        <Row gutter={12} className={cx('container')}>
            <Col span={6}>
                <p>Avatar</p>
                {
                    !isEdit ?
                        <Image
                            width={102}
                            height={102}
                            preview={imageProfile}
                            src={imageProfile ? imageProfile : img}
                        /> :
                        <>
                            <Upload
                                key="upload"
                                listType="picture-card"
                                beforeUpload={beforeUpload}
                                fileList={fileList}
                                accept="image/png, image/jpeg"
                                maxCount={1}
                                onPreview={onPreview}
                                onChange={onChange}
                                onRemove={onRemove}
                                showUploadList={{
                                    showPreviewIcon: false
                                }}
                            >
                                {fileList.length < 1 && '+ Upload'}
                            </Upload>
                        </>
                }
            </Col>
            <Col span={!isEdit ? 8 : 18}>
                {
                    isEdit ?
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}>

                            <Form.Item label="Tên" name="name" rules={[
                                { required: true }
                            ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Email" name="email" rules={[
                                { type: "email" }
                            ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Phone" name='phoneNumber' rules={[
                                { validator: checkPhoneNumber }
                            ]}>
                                <Input />
                            </Form.Item>
                            <Space>
                                <Button type="primary" htmlType="submit" loading={loading}>
                                    Lưu
                                </Button>
                                <Button danger onClick={cancelSubmit} loading={loading}>
                                    Huỷ
                                </Button>
                            </Space>
                        </Form> :
                        <div className={cx('table')}>
                            <Table dataSource={userInfo} columns={columns} pagination={false} />
                        </div>
                }
            </Col>
            <Col span={24}>
                {
                    !isEdit && <Button className={cx('img-btn')} onClick={toggleMode}>
                        Cập nhật thông tin
                    </Button>
                }
            </Col>
        </Row>
    )
}