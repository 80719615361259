import { ClearOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row, Select, Space } from "antd";
import userApi from "api/user";
import { DatePicker } from "components/";
import { ROLE } from "constants/";
import { ERROR_OCCUR } from "constants/messages";
import { selectListCategoryAds } from "features/ads/adsSlice";
import { selectListCountry } from "features/countries/countrySlice";
import { getListProductByWarehouse, selectListAllProduct, selectListProductByWarehouse } from "features/products/productSlice";
import { selectInfo, selectListTeam } from "features/users/userSlice";
import { selectListWarehouse } from "features/warehouses/warehouseSlice";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { disabledPassDate } from "utils/";
import { disabledFutureDate } from "utils/";

export default function Filter({ form, onSearch, loading }) {
    const dispatch = useDispatch()

    const teamId = Form.useWatch('team_id', form);
    const listCountry = useSelector(selectListCountry)
    const listCategoryAds = useSelector(selectListCategoryAds)
    const listWarehouse = useSelector(selectListWarehouse)
    const listProductByWarehouse = useSelector(selectListProductByWarehouse)

    const countryId = Form.useWatch('country_id', form);
    const warehouseId = Form.useWatch('warehouse_id', form);
    console.log("🚀 ~ warehouseId:", warehouseId)
    const startDate = Form.useWatch('start_date', form);
    const endDate = Form.useWatch('end_date', form);

    const listWarehouseByCountry = useMemo(() => listWarehouse.filter(item => item.country.id === countryId), [countryId, listWarehouse])

    const listTeam = useSelector(selectListTeam)
    const userInfo = useSelector(selectInfo)

    const [listUserByTeam, setListUserByTeam] = useState([])

    const fetchUser = async () => {
        form.setFieldsValue({
            user_id: null
        })
        if (!teamId) {
            setListUserByTeam([])
        }
        try {
            const res = await userApi.getAll({
                team_id: teamId
            })
            setListUserByTeam(res)
        } catch (error) {
            toast.error(error?.response?.data || ERROR_OCCUR)
        }
    }
    useEffect(() => {
        if (warehouseId) {
            dispatch(getListProductByWarehouse({ warehouse_id: warehouseId }))
        }
    }, [dispatch, warehouseId])

    useEffect(() => {
        form.setFieldsValue({
            warehouse_id: null,
            product_id: null
        })
    }, [countryId])

    useEffect(() => {
        fetchUser()
    }, [teamId])

    useEffect(() => {
        form.setFieldsValue({
            product_id: null
        })
    }, [warehouseId])

    return (
        <Card>
            <Form form={form} onFinish={() => onSearch(1)} layout='vertical' initialValues={{ start_date: moment(), end_date: moment() }}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item label='Nền tảng QC' name='category_ads_id'>
                            <Select allowClear style={{ width: '100%' }} options={listCategoryAds.map(item => ({ value: item.id, label: item.name }))} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='Ngày bắt đầu' name='start_date'>
                            <DatePicker style={{ width: '100%' }} placeholder='' disabledDate={(current) => disabledFutureDate(current, endDate)} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='Ngày kết thúc' name='end_date'>
                            <DatePicker style={{ width: '100%' }} placeholder='' disabledDate={(current) => disabledPassDate(current, startDate)} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='Thị trường' name='country_id'>
                            <Select allowClear style={{ width: '100%' }} options={listCountry.map(item => ({ value: item.id, label: item.name }))} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='Kho' name='warehouse_id'>
                            <Select disabled={!countryId} allowClear style={{ width: '100%' }} options={listWarehouseByCountry.map(item => ({ value: item.id, label: item.name }))} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='Sản phẩm' name='product_id'>
                            <Select
                                disabled={!warehouseId}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                allowClear style={{ width: '100%' }} options={listProductByWarehouse.map(item => ({ value: item.id, label: item.name }))} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    {
                        userInfo?.role?.id != ROLE.other &&
                        <Col span={8}>
                            <Form.Item label='Team' name='team_id'>
                                <Select allowClear style={{ width: '100%' }} options={listTeam.map(item => ({ value: item.id, label: item.name }))} />
                            </Form.Item>
                        </Col>
                    }

                    {
                        teamId &&
                        <Col span={8}>
                            <Form.Item label='Nhân viên' name='user_id'>
                                <Select allowClear style={{ width: '100%' }} options={listUserByTeam.map(item => ({ value: item.id, label: item.username }))} />
                            </Form.Item>
                        </Col>
                    }
                </Row>
                <Space>
                    <Button loading={loading} type="primary" htmlType='submit' icon={<SearchOutlined />}>Tìm kiếm</Button>
                    <Button loading={loading} danger icon={<ClearOutlined />} onClick={() => form.resetFields()}>Xoá bộ lọc</Button>
                </Space>
            </Form>
        </Card >
    )
};
