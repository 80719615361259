import { Layout } from "antd";
import classNames from "classnames/bind";
import { Navbar, SideBar } from "components/";
import {
  Outlet,
  Navigate
} from "react-router-dom";
import { isUserLoggedIn } from "utils";
// import { NavBar, SideBar } from "../components";
import style from './index.module.scss'

const { Content } = Layout;
const cx = classNames.bind(style)

export default function AdminLayout() {
  if (!isUserLoggedIn()) {
    return <Navigate to='/login' />
  }
  return (
    <Layout className={cx('layout')}>
      <SideBar />
      <Layout>
        <Navbar />
        <Content className={cx('content')}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}
