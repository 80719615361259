import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Space } from "antd";
import { DatePicker, InputCurrency } from "components/index";
import { selectListCategoryAds } from "features/ads/adsSlice";
import { selectListCountry } from "features/countries/countrySlice";
import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function ModalCreateUpdate({ form, onFinish, selected, show, onCancel }) {
    const listCategoryAds = useSelector(selectListCategoryAds)
    const listCountry = useSelector(selectListCountry)

    useEffect(() => {
        if (selected && show) {
            form.setFieldsValue(selected)
        }
    }, [show, selected])

    return (
        <Modal
            open={show}
            onCancel={onCancel}
            title={!selected ? 'Thêm mới' : 'Cập nhật'}
            footer=''
            width={800}
        >
            <Form form={form} onFinish={onFinish} layout='vertical'>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label='Tên danh mục' name='categoryAdsId' rules={[{ required: true }]}>
                            <Select allowClear style={{ width: '100%' }} options={listCategoryAds.map(item => ({ value: item.id, label: item.name }))} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='Ngày tạo qc' name='date' rules={[{ required: true }]}>
                            <DatePicker placeholder='' disabledDate={(current) => current && current.isAfter(moment())} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='Chi phí' name='fee' rules={[{ required: true }]}>
                            <InputCurrency />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='Thị trường' name='countryId' rules={[{ required: true }]}>
                            <Select allowClear style={{ width: '100%' }} options={listCountry.map(item => ({ value: item.id, label: item.name }))} />
                        </Form.Item>
                    </Col>
                    <Form.Item>
                        <Space>
                            <Button htmlType="submit" type='primary' size='large'>
                                {selected ? 'Cập nhật' : 'Tạo'}
                            </Button>
                            <Button size='large' onClick={onCancel}>
                                Huỷ
                            </Button>
                        </Space>
                    </Form.Item>
                </Row>
            </Form >
        </Modal >
    )
}