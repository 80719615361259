import { EyeOutlined, MailOutlined, PartitionOutlined, PhoneOutlined, UsergroupAddOutlined, UserOutlined } from "@ant-design/icons"

const listIcon = {
    name: <EyeOutlined />,
    username: <UserOutlined />,
    email: <MailOutlined />,
    phoneNumber: <PhoneOutlined />,
    roleId: <PartitionOutlined />,
    teamName: <UsergroupAddOutlined />
}

export const columns = [
    {
        title: '',
        dataIndex: 'label',
        key: 'label',
        width: '20',
        render: (label, record) => {
            return (
                <div>
                    {listIcon[record.key]}
                    <span style={{ marginLeft: 8 }}>{label}</span>
                </div>
            )
        }
    },
    {
        title: '',
        dataIndex: 'value',
        key: 'value',
    },
]