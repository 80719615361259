import { FileExcelFilled } from "@ant-design/icons";
import { Button, Card, Form, Tabs, Typography } from "antd";
import classNames from "classnames/bind";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { ColumnChartTab, Filter, PieChartTab, Top5EmployeeTable, Top5ProductTable, Top5TeamTable } from "./components";
import style from './index.module.scss'
import { ERROR_OCCUR } from "constants/messages";
import moment from "moment";
import RevenueCard from "./components/revenue";
import statisticApi from "api/statistic";
import { useSelector } from "react-redux";
import { selectInfo } from "features/users/userSlice";
import { ROLE } from "constants/";

const cx = classNames.bind(style)

export default function StatisticPage() {
    const [formSearch] = Form.useForm()

    const userInfo = useSelector(selectInfo)

    const [statistic, setStatistic] = useState([])
    const [loading, setLoading] = useState(false)
    const [openCU, setOpenCU] = useState(false)

    const onOpenCreate = () => {
        setOpenCU(true)
    }

    const onExportProduct = () => {

    }

    const tabs = useMemo(() => [
        {
            key: '1',
            label: 'DS theo team',
            children: <>
                <div className={cx('sub-header')}>
                </div>
                <Top5TeamTable />
            </>,
        },
        {
            key: '2',
            label: 'BXH nhân viên',
            children: <Top5EmployeeTable />,
        },
        {
            key: '3',
            label: 'DS theo sản phẩm',
            children: <Top5ProductTable />,
        },
        {
            key: '4',
            label: 'Biểu đồ tròn',
            children: <PieChartTab />,
            hide: userInfo?.role?.id === ROLE.other,
        },
        {
            key: '5',
            label: 'Biểu đồ cột',
            children: <ColumnChartTab />,
            hide: userInfo?.role?.id === ROLE.other,
        },
    ], []);

    const onSearch = async () => {
        try {
            const values = formSearch.getFieldsValue()
            setLoading(true)
            const res = await statisticApi.revenue({
                ...values,
                start_date: values.start_date && values.start_date.format('YYYY-MM-DD'),
                end_date: values.end_date && values.end_date.format('YYYY-MM-DD'),
            })
            setStatistic(res)
        } catch (error) {
            toast.error(error?.response?.data || ERROR_OCCUR)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        onSearch()
    }, [])

    return (
        <>
            <div className={cx('header')}>
                <Typography.Title level={3} >Thống kê chi tiết</Typography.Title>
            </div>
            <Filter form={formSearch} onSearch={onSearch} loading={loading} />
            <br />
            <RevenueCard statistic={statistic} />
            <br />
            <Card>
                <Tabs defaultActiveKey="1" items={tabs.filter(tab => !tab.hide)} />
            </Card>
        </>
    )
};
