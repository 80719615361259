import { Button, Form, Typography } from "antd";
import classNames from "classnames/bind";
import style from './index.module.scss'
import { FileExcelFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { FormFilter, TableList } from "./components";
import { toast } from "react-toastify";
import { ERROR_OCCUR } from "constants/messages";
import adsApi from "api/ads";
import userApi from "api/user";
import { getUser } from "utils/";
import { ROLE } from "constants/";
import { numberWithCommas } from "utils/";

const cx = classNames.bind(style)
export default function AdsFeePage() {
    const [formSearch] = Form.useForm()

    const [listAdsFee, setListAdsFee] = useState([])
    const [listEmployee, setListEmployee] = useState([])
    const [rangeDate, setRangeDate] = useState([])
    const [userId, setUserId] = useState()
    const [loading, setLoading] = useState(false)
    const [totalFee, setTotalFee] = useState(0)

    const onSearch = async () => {
        try {
            setLoading(true)
            const { range, user_id, category_ads_id, team_id } = formSearch.getFieldsValue()
            const res = await adsApi.getByUser({
                category_ads_id,
                start_date: range[0] && range[0].format('YYYY-MM-DD'),
                end_date: range[1] && range[1].format('YYYY-MM-DD'),
                team_id,
                user_id,
            })
            setUserId(user_id)
            setListAdsFee(res)
            setRangeDate([range[0].format('YYYY-MM-DD'), range[1].format('YYYY-MM-DD')])
            setTotalFee(res.reduce((curr, next, index) => {
                return next?.adsResponseWithCountry ? curr + next.adsResponseWithCountry.reduce((subCur, subNext, idx) => subCur + subNext.fee, 0) : curr
            }, 0))
        } catch (error) {
            toast.error(error?.response?.data || ERROR_OCCUR)
        } finally {
            setLoading(false)
        }
    }

    const onExport = async () => {
        try {
            setLoading(true)
            const { user_id, range } = formSearch.getFieldsValue()
            const res = await adsApi.export(user_id, {
                start_date: range[0] && range[0].format('YYYY-MM-DD'),
                end_date: range[1] && range[1].format('YYYY-MM-DD'),
            })
            const a = document.createElement('a')
            a.href = URL.createObjectURL(res)
            a.download = 'chi_phi_qc.xlsx'
            a.click()
        } catch (error) {
            console.log("🚀 ~ error:", error)
            toast.error(error?.response?.data || ERROR_OCCUR)
        } finally {
            setLoading(false)
        }
    }

    const fetchListEmployee = async () => {
        try {
            const res = await userApi.getAll({
                role_id: ROLE.other,
            })
            setListEmployee(res)
        } catch (error) {
            console.log("🚀 ~ error:", error)
        }
    }

    useEffect(() => {
        if (getUser().role.id == ROLE.other) {
            setListEmployee([{ id: getUser().id, username: getUser().username }])
            formSearch.setFieldsValue({
                user_id: getUser().id
            })
        } else {
            fetchListEmployee()
        }
    }, [])

    return (
        <>
            <div className={cx('header')}>
                <Typography.Title level={3} >Chi phí quảng cáo</Typography.Title>
                <Button type="primary" size="large" icon={<FileExcelFilled />} onClick={onExport}>
                    Xuất Excel
                </Button>
            </div>
            <FormFilter listEmployee={listEmployee} form={formSearch} onSearch={onSearch} loading={loading} />
            <h3>Tổng chi phí: {numberWithCommas(totalFee)}</h3>
            <TableList
                onSearch={onSearch}
                categoryAdsId={formSearch.getFieldValue('category_ads_id')}
                userId={userId}
                rangeDate={rangeDate}
                list={listAdsFee}
                loading={loading}
            />
        </>
    )
}