import { Button, Form, Typography } from "antd";
import classNames from "classnames/bind";
import style from './index.module.scss'
import { PlusCircleFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { ModalCreateUpdate, UserFilter, UserTable } from "./components";
import { ModalDelete } from "components";
import userApi from "api/user";
import { toast } from "react-toastify";
import { ERROR_OCCUR } from "constants/messages";
import { getUser } from "utils/";
import { ROLE } from "constants/";

const cx = classNames.bind(style)
export default function UserPage() {
    const [formSearch] = Form.useForm()
    const [formUser] = Form.useForm();

    const [listUser, setListUser] = useState([])
    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState(null)
    const [openCU, setOpenCU] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)

    const openCreate = () => {
        setOpenCU(true)
    }

    const openEdit = (item) => {
        setOpenCU(true)
        setSelected(item)
    }

    const onOpenDelete = (item) => {
        setOpenDelete(true)
        setSelected(item)
    }

    const closeModalCU = () => {
        setOpenCU(false)
        setSelected(null)
        formUser.resetFields()
    }

    const closeModalDelete = () => {
        setOpenDelete(false)
        setSelected(null)
    }

    const onSearch = async (values) => {
        try {
            setLoading(true)
            const res = await userApi.getAll(values)
            setListUser(res)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const onDelete = async (values) => {
        try {
            await userApi.delete(selected.id)
            closeModalDelete()
            onSearch()
            toast.success('Xoá thành công')
        } catch (error) {
            toast.error(error?.response?.data || ERROR_OCCUR)
        }
    }

    const onCreateAndUpdate = async (values) => {
        try {
            setLoading(true)
            if (selected) {
                await userApi.changeRoleTeam(selected.id, values)
                toast.success('Cập nhật thành công')
            } else {
                await userApi.create(values)
                toast.success('Tạo thành công')
            }
            closeModalCU()
            onSearch()
        } catch (error) {
            toast.error(error?.response?.data)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        onSearch()
    }, [])

    return (
        <>
            <div className={cx('header')}>
                <Typography.Title level={3} >Danh sách nhân viên</Typography.Title>
                {
                    (getUser().role.id == ROLE.super_admin || getUser().role.id == ROLE.admin) &&
                    <Button size="large" icon={<PlusCircleFilled />} onClick={openCreate}>
                        Thêm mới
                    </Button>
                }
            </div>
            <UserFilter form={formSearch} loading={loading} onSearch={onSearch} />
            <UserTable onSearch={onSearch} onEdit={openEdit} onDelete={onOpenDelete} users={listUser} loading={loading} />
            <ModalCreateUpdate
                form={formUser}
                onFinish={onCreateAndUpdate}
                selected={selected}
                show={openCU}
                onCancel={closeModalCU} />

            <ModalDelete title='Xóa nhân viên' show={openDelete} onOk={onDelete} onCancel={closeModalDelete} />

        </>
    )
}