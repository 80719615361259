import { CloseOutlined, EditOutlined, FileSyncOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Space, Table } from "antd";
import adsApi from "api/ads";
import classNames from "classnames/bind";
import { InputCurrency } from "components/";
import { selectListCountry } from "features/warehouses/countrySlice";
import { cloneDeep } from "lodash";
import moment from "moment";
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getUser } from "utils/";
import { getDatesInRange } from "utils/";
import { numberWithCommas } from "utils/";
import style from './index.module.scss'

const cx = classNames.bind(style)
const EditableContext = createContext(null);
export default function TableList({ onSearch, rangeDate, list, userId, categoryAdsId }) {
    const listCountry = useSelector(selectListCountry)

    const [loading, setLoading] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [dataSource, setDataSource] = useState([])

    const toggleModeEdit = () => {
        setEditMode(true)
    }

    const cancelEdit = () => {
        setEditMode(false)
        handleSyncData()
    }

    const handleSyncData = useCallback(() => {
        const formatRange = getDatesInRange(rangeDate[0], rangeDate[1])

        setDataSource(formatRange.reverse().map(date => {
            const index = list.findIndex(d => d.date === date)
            const res = {
                date
            }
            listCountry.map(country => {
                res[`country_${country.id}`] = null
            })
            if (index !== -1) {
                list[index].adsResponseWithCountry?.map(ads => {
                    res[`country_${+ads.countryId}`] = ads.fee
                    res[`ads_id_${+ads.countryId}`] = +ads.id
                })
            }

            return res
        }))

    }, [rangeDate, list, listCountry])

    const saveEdit = async () => {
        try {
            setLoading(true)
            const listAdsUpdate = []
            listCountry.map(country => {
                return dataSource
                    .filter(data => data[`country_${country.id}`] !== null)
                    .map((data) =>
                        listAdsUpdate.push({
                            id: data[`ads_id_${country.id}`] ? data[`ads_id_${country.id}`] : "",
                            categoryAdsId,
                            date: data.date,
                            countryId: country.id,
                            fee: data[`country_${country.id}`]
                        })
                    )
            })
            await adsApi.createAndUpdate({
                requests: listAdsUpdate
            })

            setEditMode(false)
            onSearch()
            toast.success('Lưu thay đổi thành công')
        } catch (error) {
            toast.error('Lưu thay đổi thất bại')
        } finally {
            setLoading(false)
        }
    }

    const handleSave = useCallback((row) => {
        const newData = cloneDeep(dataSource);
        const index = newData.findIndex((item) => row.date === item.date);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });

        setDataSource(newData);
    }, [dataSource]);

    const EditableRow = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const EditableCell = ({
        title,
        editable,
        children,
        dataIndex,
        record,
        handleSave,
        ...restProps
    }) => {
        const [editing, setEditing] = useState(false);
        const inputRef = useRef(null);
        const form = useContext(EditableContext);
        useEffect(() => {
            if (editing) {
                inputRef.current.focus();
            }
        }, [editing]);
        const toggleEdit = () => {
            if (!editMode) return
            setEditing(!editing);
            form.setFieldsValue({
                [dataIndex]: record[dataIndex],
            });
        };
        const save = async (dataIndex) => {
            try {
                const values = await form.validateFields();
                toggleEdit();
                handleSave({
                    ...record,
                    ...values,
                });
            } catch (errInfo) {
                console.log('Save failed:', errInfo);
            }
        };

        let childNode = children;
        if (editable) {
            childNode = editing ? (
                <Form.Item
                    style={{
                        margin: 0,
                    }}
                    name={dataIndex}
                >
                    <InputCurrency ref={inputRef} onPressEnter={() => save(dataIndex)} onBlur={() => save(dataIndex)} />
                </Form.Item>
            ) : (
                <div
                    className={cx({ 'editable-cell-value-wrap': editMode })}
                    onClick={toggleEdit}
                >
                    {children}
                </div>
            );
        }
        return <td {...restProps}>{childNode}</td>;
    };

    const handleOnCell = useCallback((record, title, dataIndex, style) => {

        return {
            record,
            editable: true,
            dataIndex,
            title,
            handleSave,
            style
        }
    }, [handleSave])

    const columns = useMemo(() => {
        const res = [
            {
                key: 'date',
                dataIndex: 'date',
                title: 'Ngày',
                width: '10%',
                render: (_, record) => moment().subtract(1, 'd').format('YYYY-MM-DD') === _ ? <b>{_}</b> : _
            },
            {
                key: 'sum',
                dataIndex: 'sum',
                width: '15%',
                title: 'Tổng các thị trường',
                render: (_, record) => {
                    let sum = 0
                    Object.keys(record).map((key) => {
                        if (key.includes('country_') && record[key]) {
                            sum += record[key]
                        }

                    })
                    return numberWithCommas(sum)
                }
            },
        ]

        listCountry.map(item => {
            res.push({
                key: `country_${item.id}`,
                dataIndex: `country_${item.id}`,
                title: item.name,

                render: (_) => {
                    // return _ ? numberWithCommas(_) + ' ' + item.currency : 0
                    return _ ? numberWithCommas(_) : 0
                },
                onCell: (record) => handleOnCell(record, item.name, `country_${item.id}`)
            })
        })

        return res
    }, [handleOnCell, listCountry])

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    useEffect(() => {
        if (rangeDate.length > 0) {
            handleSyncData()
        }
    }, [handleSyncData, list, rangeDate])

    return (
        <>
            <Divider />
            {
                userId == getUser().id && !editMode && dataSource.length > 0 && <Button icon={<EditOutlined />} type='primary' onClick={toggleModeEdit}>Cập nhật</Button>
            }
            {
                editMode &&
                <Space>
                    <Button loading={loading} icon={<FileSyncOutlined />} type='primary' onClick={saveEdit}>Lưu thay đổi</Button>
                    <Button loading={loading} icon={<CloseOutlined />} danger onClick={cancelEdit}>Huỷ</Button>
                </Space>
            }

            <Table
                rowClassName={() => cx('editable-row')}
                components={components}
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                pagination={false} />
        </>
    )
};
