import { ClearOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row, Select, Space, Upload } from "antd";
import { DatePicker } from "components/";
import { PRODUCT_STATUS } from "constants/";
import { selectListCountry } from "features/countries/countrySlice";
import { selectListCategory } from "features/products/productSlice";
import { selectListWarehouse } from "features/warehouses/warehouseSlice";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { disabledPassDate, disabledFutureDate } from "utils/";

export default function ProductFilter({ form, onSearch, loading }) {
    const listCategory = useSelector(selectListCategory)
    const listCountry = useSelector(selectListCountry)
    const listWarehouse = useSelector(selectListWarehouse)

    const countryId = Form.useWatch('country_id', form);
    const createdAt = Form.useWatch('created_at', form);
    const endDate = Form.useWatch('end_date', form);

    const listWarehouseByCountry = useMemo(() => listWarehouse.filter(item => item.country.id === countryId), [countryId, listWarehouse])

    return (
        <Card>
            <Form form={form} onFinish={() => onSearch(1)} layout='vertical'>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item label='Danh mục sản phẩm' name='category_id'>
                            <Select allowClear style={{ width: '100%' }} options={listCategory.map(item => ({ value: item.id, label: item.name }))} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='Tên sản phẩm' name='name' >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='Trạng thái' name='status' >
                            <Select allowClear style={{ width: '100%' }}>
                                {
                                    PRODUCT_STATUS.map(item => (
                                        <Select.Option key={item.value} value={item.value}>
                                            {item.label}
                                        </Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label='Thị trường' name='country_id'>
                            <Select allowClear style={{ width: '100%' }} options={listCountry.map(item => ({ value: item.id, label: item.name }))} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label='Kho' name='warehouse_id'>
                            <Select allowClear style={{ width: '100%' }} options={listWarehouseByCountry.map(item => ({ value: item.id, label: item.name }))} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label='Ngày bắt đầu' name='created_at'>
                            <DatePicker style={{ width: '100%' }} placeholder='' disabledDate={(current) => disabledFutureDate(current, endDate)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label='Ngày kết thúc' name='end_date'>
                            <DatePicker style={{ width: '100%' }} placeholder='' disabledDate={(current) => disabledPassDate(current, createdAt)} />
                        </Form.Item>
                    </Col>
                </Row>
                <Space>
                    <Button loading={loading} type="primary" htmlType='submit' icon={<SearchOutlined />}>Tìm kiếm</Button>
                    <Button loading={loading} danger icon={<ClearOutlined />} onClick={() => form.resetFields()}>Xoá bộ lọc</Button>
                </Space>
            </Form>
        </Card >
    )
};
