import axiosService from "./axiosService"

const statisticApi = {
    revenue(body) {
        const url = '/statistic/revenue'
        return axiosService.post(url, null, { params: body })
    },
    getTop5User(params) {
        const url = '/statistic/top-5-user'
        return axiosService.get(url, { params })
    },
    getTop5Product(params) {
        const url = '/statistic/top-5-product'
        return axiosService.get(url, { params })
    },
    getTopTeam(params) {
        const url = '/statistic/revenue/top-team'
        return axiosService.get(url, { params })
    },
    getPieChart(params) {
        const url = '/statistic/pie-chart'
        return axiosService.get(url, { params })
    },
    getPieChartActual(params) {
        const url = '/statistic/pie-chart-actual'
        return axiosService.get(url, { params })
    },
    getColChart(params) {
        const url = '/statistic/column-chart'
        return axiosService.get(url, { params })
    },
    exportTop5User(params) {
        let url = `/statistic/top-5-user/export?from=${params.from}&to=${params.to}`
        if (params.team_id) {
            url += `&team_id=${params.team_id}`
        }
        if (params.user_id) {
            url += `&user_id=${params.user_id}`
        }
        return axiosService.get(url, { responseType: 'blob' })
    },
    exportTop5Product(params) {
        let url = `/statistic/top-5-product/export?from=${params.from}&to=${params.to}`
        if (params.team_id) {
            url += `&team_id=${params.team_id}`
        }
        if (params.user_id) {
            url += `&user_id=${params.user_id}`
        }
        return axiosService.get(url, { responseType: 'blob' })
    },
}

export default statisticApi;