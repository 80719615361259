import { Button, Col, Form, Radio, Row, Select, Space } from 'antd';
import { useEffect, useState } from 'react';
import { Area, Pie, Column } from "@ant-design/plots";
import moment from 'moment';
import { DatePicker } from 'components/';
import { SearchOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { ERROR_OCCUR } from 'constants/messages';
import statisticApi from 'api/statistic';
import { disabledFutureDate } from 'utils/';
import { disabledPassDate } from 'utils/';
import productApi from 'api/product';

const leftConfig = {
    appendPadding: 10,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
        type: 'inner',
        offset: '-30%',
        content: ({ percent }) => percent ? `${(percent * 100).toFixed(0)}%` : '',
        style: {
            fontSize: 14,
            textAlign: 'center',
        },
    },
    interactions: [
        {
            type: 'element-active',
        },
    ],
};

const rightConfig = {
    appendPadding: 10,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
        type: 'inner',
        offset: '-30%',
        content: ({ percent }) => percent ? `${(percent * 100).toFixed(0)}%` : '',
        style: {
            fontSize: 14,
            textAlign: 'center',
        },
    },
    interactions: [
        {
            type: 'element-active',
        },
    ],
};

const initialValues = { start_date: moment().startOf('M'), end_date: moment(), type: 'all' }
const listStatus = {
    opp: 'OPP',
    accepted: 'ACCEPTED',
    inTransit: 'IN TRANSIT',
    forReturn: 'FOR RETURN',
    returned: 'RETURNED',
    delivering: 'DELIVERING',
    delivered: 'DELIVERED',
    cancel: 'CANCEL',
    productDuplicate: 'PRODUCT DUPLICATE',
}

export default function PieChartTab() {
    const [dataEstimate, setDataEstimate] = useState([])
    const [dataActual, setDataActual] = useState([])
    const [totalEst, setTotalEst] = useState(0)
    const [totalAct, setTotalAct] = useState(0)
    const [loading, setLoading] = useState(false)
    const [listProduct, setListProduct] = useState([])

    const [form] = Form.useForm()

    const startDate = Form.useWatch('start_date', form);
    const endDate = Form.useWatch('end_date', form);

    const onSubmit = async (values) => {
        try {
            setLoading(true)
            const resEst = await statisticApi.getPieChart({
                start_date: moment(values.start_date).format('YYYY-MM-DD'),
                end_date: moment(values.end_date).format('YYYY-MM-DD'),
                prd_id: values.prd_id
            })
            const resAct = await statisticApi.getPieChartActual({
                start_date: moment(values.start_date).format('YYYY-MM-DD'),
                end_date: moment(values.end_date).format('YYYY-MM-DD'),
                prd_id: values.prd_id
            })
            const newDataEst = []
            const newDataAct = []
            Object.keys(resEst).map(key => {
                if (key === 'total') {
                    setTotalEst(resEst[key])
                } else {
                    newDataEst.push({
                        type: listStatus[key],
                        value: resEst[key]
                    })
                }
            })
            Object.keys(resAct).map(key => {
                if (key === 'total') {
                    setTotalAct(resAct[key])
                } else {
                    resAct[key] !== null && newDataAct.push({
                        type: listStatus[key],
                        value: resAct[key]
                    })
                }
            })
            newDataEst.push(newDataEst.shift())
            newDataEst.push(newDataEst.shift())            
            setDataEstimate(newDataEst)
            setDataActual(newDataAct)
        } catch (error) {
            console.log("🚀 ~ error:", error)
            toast.error(error?.response?.data || ERROR_OCCUR)
        } finally {
            setLoading(false)
        }
    }

    const fetchAllProduct = async () => {
        try {
            const res = await productApi.search({
                page_size: 1000000
            })
            setListProduct(res.responses)
        } catch (error) {
            console.log("🚀 ~ error:", error)
            toast.error(error?.response?.data || ERROR_OCCUR)
        }
    }


    useEffect(() => {
        fetchAllProduct()
        onSubmit(initialValues)
    }, [])


    return (
        <>
            <Form form={form} onFinish={onSubmit} layout='vertical' initialValues={initialValues}>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item label='Ngày bắt đầu' name='start_date' rules={[{ required: true }]}>
                            <DatePicker style={{ width: '100%' }} placeholder='' disabledDate={(current) => disabledFutureDate(current, endDate)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label='Ngày kết thúc' name='end_date' rules={[{ required: true }]}>
                            <DatePicker style={{ width: '100%' }} placeholder='' disabledDate={(current) => disabledPassDate(current, startDate)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label='Sản phẩm' name='prd_id'>
                            <Select
                                showSearch
                                allowClear
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                style={{ width: '100%' }}
                                options={listProduct.map(item => ({ value: item.id, label: item.name }))} />
                        </Form.Item>
                    </Col>
                </Row>
                <Space>
                    <Button loading={loading} type="primary" htmlType='submit' icon={<SearchOutlined />}>Tìm kiếm</Button>
                </Space>
            </Form>
            <Row gutter={16}>
                <Col span={12}>
                    <h3>Tổng đơn ước tính: {totalEst}</h3>
                    <Pie
                        data={dataEstimate}
                        {...leftConfig}
                    />
                </Col>
                <Col span={12}>
                    <h3>Tổng đơn thực tế: {totalAct}</h3>
                    <Pie
                        data={dataActual}
                        {...rightConfig}
                    />
                </Col>
            </Row>
        </>
    )
}