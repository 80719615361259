import { Badge, Descriptions, Divider, Modal } from "antd";
import { PRODUCT_STATUS } from "constants/";
import { numberWithCommas } from "utils/";

export default function ModalDetail({ show, selected, onCancel }) {
    return (
        <Modal width={1000} title='Chi tiết sản phẩm' open={show} onCancel={onCancel} footer=''>
            <Descriptions bordered column={3}>
                <Descriptions.Item label='Danh mục SP'>
                    {selected?.categoryProducts?.name}
                </Descriptions.Item>
                <Descriptions.Item label='Tên sản phẩm'>
                    {selected?.name}
                </Descriptions.Item>
                <Descriptions.Item label='Mã SP'>
                    {selected?.productCode}
                </Descriptions.Item>
                <Descriptions.Item label='Trạng thái'>
                    {
                        selected?.productStatus && (selected.productStatus == 1 ? <Badge status="processing" text={PRODUCT_STATUS[0].label} /> : <Badge status="error" text={PRODUCT_STATUS[1].label} />)
                    }
                </Descriptions.Item>
                <Descriptions.Item label='Giá'>
                    {numberWithCommas(Number(selected?.price)?.toFixed(2) * selected?.productStockResponses?.[0]?.countries.rate || 0) + ' ' + selected?.productStockResponses?.[0]?.countries.currency || ''}
                </Descriptions.Item>
                <Descriptions.Item label='Thị trường'>
                    {selected?.productStockResponses?.[0]?.countries?.name}
                </Descriptions.Item>
            </Descriptions>
            {
                selected?.productStockResponses.map(warehouse => (
                    <>
                        <Divider />
                        <Descriptions title={`Kho: ${warehouse?.warehouse.name}`} bordered column={2}>
                            {
                                warehouse.productVariantResponses.map(variant => (
                                    <Descriptions.Item key={variant.variant.id} label={`Thuộc tính: ${variant.variant.name}`} span={2}>
                                        Số lượng: {variant?.quantity}
                                    </Descriptions.Item>
                                ))
                            }
                        </Descriptions>
                    </>
                ))
            }
        </Modal>
    )
}