import { configureStore } from '@reduxjs/toolkit';
import adsSlice from 'features/ads/adsSlice';
import comboSlice from 'features/combos/comboSlice';
import countrySlice from 'features/countries/countrySlice';
import productSlice from 'features/products/productSlice';
import userSlice from 'features/users/userSlice';
import warehouseSlice from 'features/warehouses/warehouseSlice';

export default configureStore({
    reducer: {
        product: productSlice,
        country: countrySlice,
        warehouse: warehouseSlice,
        combo: comboSlice,
        user: userSlice,
        ads: adsSlice
    },
});
