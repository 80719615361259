import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Badge, Button, Image, Space, Tag } from "antd";
import { ROLE } from "constants/";
import { COMBO_STATUS } from "constants/";
import { getUser } from "utils/";
import { numberWithCommas } from "utils/";

export const renderColumns = (current, pageSize, openEdit, openDetail) => [
    {
        key: 'stt',
        dataIndex: 'stt',
        title: 'STT',
        render: (_, record, index) => (index + 1) + (current - 1) * pageSize
    },
    {
        key: 'topic',
        dataIndex: 'topic',
        title: 'Chủ đề',
        render: (_) => _.name
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: 'Trạng thái',
        render: (_) => _ == 1 ? <Badge status="processing" text={COMBO_STATUS[0].label} /> : <Badge status="error" text={COMBO_STATUS[1].label} />
    },
    {
        key: 'updatedAt',
        dataIndex: 'updatedAt',
        title: 'Ngày cập nhật',
    },
    {
        key: 'comboDetailList',
        dataIndex: 'comboDetailList',
        title: 'Sản phẩm chính',
        render: (_) => _.find(item => item.isMain === "true")?.productName
    },
    {
        key: 'name',
        dataIndex: 'name',
        title: 'Tên CTKM',
    },
    {
        key: 'description',
        dataIndex: 'description',
        title: 'Mô tả',
    },
    {
        key: 'price',
        dataIndex: 'price',
        title: 'Giá',
        render: (_, record) => numberWithCommas(Number(_)) + ' ' + record.countries.currency
    },
    {
        key: 'action',
        dataIndex: 'action',
        title: 'Hành động',
        render: (_, record) => (
            <Space>
                <Button onClick={() => openDetail(record)} icon={<EyeOutlined />} />
                {getUser()?.role?.id != ROLE.other && <Button onClick={() => openEdit(record)} icon={<EditOutlined />} />}
            </Space>
        )
    }

]