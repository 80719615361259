import { Button, Form, Typography } from "antd";
import classNames from "classnames/bind";
import style from './index.module.scss'
import { PlusCircleFilled } from "@ant-design/icons";
import { useState } from "react";
import { ModalCreateUpdate, TableList } from "./components";
import { ModalDelete } from "components";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ERROR_OCCUR } from "constants/messages";
import { getListVariant, selectListVariant } from "features/products/productSlice";
import variantApi from "api/variant";
import { getUser } from "utils/";
import { ROLE } from "constants/";

const cx = classNames.bind(style)
export default function VariantPage() {
    const dispatch = useDispatch();

    const listVariant = useSelector(selectListVariant)

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState(null)
    const [openCU, setOpenCU] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)

    const openCreate = () => {
        setOpenCU(true)
    }

    const onOpenEdit = (item) => {
        setOpenCU(true)
        setSelected(item)
    }

    const onOpenDelete = (item) => {
        setOpenDelete(true)
        setSelected(item)
    }

    const closeModalCU = () => {
        setOpenCU(false)
        setSelected(null)
        form.resetFields()
    }

    const closeModalDelete = () => {
        setOpenDelete(false)
        setSelected(null)
    }


    const onDelete = async () => {
        try {
            await variantApi.delete(selected.id)
            closeModalDelete()
            dispatch(getListVariant())
            toast.success('Xoá thành công')
        } catch (error) {
            toast.error(error?.response?.data || ERROR_OCCUR)
        }
    }

    const onCreateAndUpdate = async (values) => {
        try {
            setLoading(true)
            if (selected) {
                await variantApi.createAndUpdate({
                    ...values,
                    id: selected.id
                })
            } else {
                await variantApi.createAndUpdate(values)
            }
            dispatch(getListVariant())
            toast.success(selected ? 'Cập nhật thành công' : 'Tạo thành công')
            closeModalCU()
        } catch (error) {
            toast.error(error?.response?.data || ERROR_OCCUR)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <div className={cx('header')}>
                <Typography.Title level={3} >Danh sách thuộc tính</Typography.Title>
                {
                    (getUser().role.id == ROLE.super_admin || getUser().role.id == ROLE.admin) &&
                    <Button size="large" icon={<PlusCircleFilled />} onClick={openCreate}>
                        Thêm mới
                    </Button>
                }
            </div>
            <TableList
                list={listVariant}
                loading={loading}
                openEdit={onOpenEdit}
                openDelete={onOpenDelete}
            />
            <ModalCreateUpdate
                form={form}
                onFinish={onCreateAndUpdate}
                selected={selected}
                show={openCU}
                onCancel={closeModalCU} />

            <ModalDelete title='Xóa thuộc tính' show={openDelete} onOk={onDelete} onCancel={closeModalDelete} />
        </>
    )
}