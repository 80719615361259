import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, InputNumber, Row, Select, Space } from "antd";
import variantApi from "api/variant";
import classNames from "classnames/bind";
import { ERROR_OCCUR } from "constants/messages";
import { ModalCreateUpdate } from "features/products/pages/variant/components";
import { getListVariant, selectListVariant } from "features/products/productSlice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import style from './index.module.scss'

const cx = classNames.bind(style)

export default function WarehouseForm({ listWarehouse }) {
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const listVariant = useSelector(selectListVariant)

    const [openVariant, setOpenVariant] = useState(false)

    const openCreate = () => {
        setOpenVariant(true)
    }

    const closeModal = () => {
        setOpenVariant(false)
        form.resetFields()
    }

    const onSubmit = async (values) => {
        try {
            await variantApi.createAndUpdate(values)
            dispatch(getListVariant())
            toast.success('Tạo thành công')
            closeModal()
        } catch (error) {
            toast.error(error?.response?.data || ERROR_OCCUR)
        }
    }

    return (
        <>
            <ModalCreateUpdate
                form={form}
                onFinish={onSubmit}
                show={openVariant}
                onCancel={closeModal} />
            <Form.List name="warehouse" rules={[{
                validator: async (_, names) => {
                    if (!names || names.length < 1) {
                        return Promise.reject(new Error('Phải thêm ít nhất 1 kho'));
                    }
                },
            },]}>
                {(warehouseFields, { add, remove }, { errors }) => (
                    <>
                        {warehouseFields.map((warehouse) => (
                            <div key={warehouse.key} className={cx('product-stock')}>
                                <Space align="center">
                                    <Form.Item
                                        label='Kho'
                                        {...warehouse}
                                        name={[warehouse.name, 'warehouseId']}
                                        rules={[{ required: true }]}
                                    >
                                        <Select
                                            style={{ minWidth: '200px' }}
                                            placeholder='Chọn kho'
                                            options={listWarehouse.map(item => ({ value: item.id, label: item.name, disabled: item.disabled }))} />
                                    </Form.Item>
                                    <Form.Item
                                        {...warehouse}
                                        label='Tổng số lượng'
                                        name={[warehouse.name, 'quantity']}
                                        rules={[{ required: true }]}
                                    >
                                        <InputNumber disabled min={0} />
                                    </Form.Item>
                                    <MinusCircleOutlined className={cx('remove-btn')} onClick={() => remove(warehouse.name)} />
                                </Space>
                                <Row>
                                    <Form.List name={[warehouse.name, 'variant']} rules={[{
                                        validator: async (_, names) => {
                                            if (!names || names.length < 1) {
                                                return Promise.reject(new Error('Phải thêm ít nhất 1 thuộc tính'));
                                            }
                                            if (names.length > 0) {
                                                const listId = []
                                                let error = false
                                                names.map(item => {
                                                    if (item?.variantId && !listId.includes(item.variantId)) {
                                                        listId.push(item.variantId)
                                                    } else if (item?.variantId && listId.includes(item.variantId)) {
                                                        error = true
                                                    }
                                                })
                                                if (error) return Promise.reject(new Error('Không được chọn trùng thuộc tính'));
                                            }
                                        },
                                    },]}>
                                        {(variantFields, { add, remove }, { errors }) => (
                                            <>
                                                <Col span={24}>
                                                    {variantFields.map(variant => {
                                                        return (
                                                            <Space className={cx('product-variant')} key={variant.key} align='center'>
                                                                <Form.Item
                                                                    label='Thuộc tính'
                                                                    {...variant}
                                                                    name={[variant.name, 'variantId']}
                                                                    rules={[{ required: true }]}
                                                                >
                                                                    <Select
                                                                        optionFilterProp="children"
                                                                        showSearch
                                                                        filterOption={(input, option) =>
                                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                        }
                                                                        style={{ minWidth: '200px' }}
                                                                        dropdownRender={(menu) => (
                                                                            <>
                                                                                {menu}
                                                                                <Divider style={{
                                                                                    margin: '8px 0',
                                                                                }} />
                                                                                <Button onClick={() => openCreate()} block icon={<PlusOutlined />}>
                                                                                    Tạo thuộc tính mới
                                                                                </Button>
                                                                            </>
                                                                        )}
                                                                        options={listVariant.map(item => ({ value: item.id, label: item.name, disabled: item.disabled }))}
                                                                    />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    label='Số lượng'
                                                                    {...variant}
                                                                    name={[variant.name, 'quantity']}
                                                                    rules={[{ required: true }]}
                                                                >
                                                                    <InputNumber min={1} />
                                                                </Form.Item>
                                                                <MinusCircleOutlined onClick={() => remove(variant.name)} />
                                                            </Space>
                                                        )
                                                    })}
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item>
                                                        <Button disabled={listVariant.length === variantFields.length} type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                                                            Thêm thuộc tính
                                                        </Button>
                                                        <Form.ErrorList errors={errors} />
                                                    </Form.Item>
                                                </Col>
                                            </>
                                        )}
                                    </Form.List>
                                </Row>
                            </div>
                        ))}

                        <Form.Item>
                            <Button disabled={warehouseFields.length === listWarehouse.length} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Thêm kho
                            </Button>
                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </>
    )
}