import { Button, Card, Form, Space, Typography } from "antd";
import classNames from "classnames/bind";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ApiTable, Filter, ModalEdit } from "./components";
import style from './index.module.scss'
import { ERROR_OCCUR } from "constants/messages";
import moment from "moment";
import ladiPageApi from "api/ladipage";
import { Link } from "react-router-dom";
import { PlusCircleFilled } from "@ant-design/icons";

const cx = classNames.bind(style)

export default function ListApiPage() {
    const [formSearch] = Form.useForm()
    const [formApi] = Form.useForm();

    const [pagination, setPagination] = useState({
        current_page: 1,
        page_size: 20,
    })
    const [total, setTotal] = useState(0)
    const [apis, setApis] = useState([])
    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState(null)
    const [openEdit, setOpenEdit] = useState(false)

    const onOpenEdit = async (item) => {
        setSelected(item)
        setOpenEdit(true)
    }

    const closeModalEdit = () => {
        setOpenEdit(false)
        setSelected(null)
        formApi.resetFields()
    }

    const onChangePage = (page) => {
        setPagination({
            ...pagination,
            current_page: page
        })
        onSearch(page)
    }

    const onSearch = async (page = pagination.current_page) => {
        try {
            const values = formSearch.getFieldsValue()
            setLoading(true)
            const res = await ladiPageApi.search({
                ...values,
                ...pagination,
                current_page: page - 1,
            })
            setApis(res.responses)
            setTotal(res.totalRecord)
        } catch (error) {
            toast.error(error?.response?.data || ERROR_OCCUR)
        } finally {
            setLoading(false)
        }
    }

    const onSubmitEdit = async (values) => {
        console.log("🚀 ~ values:", values)
        try {
            setLoading(true)
            await ladiPageApi.createAndUpdate({
                id: selected.ladingPageId,
                ...values,
            })
            toast.success('Sửa API thành công')
            closeModalEdit()
            onSearch()
        } catch (error) {
            toast.error(error?.response?.data || 'Sửa API thất bại')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        setTimeout(() => {
            onSearch()
        }, 500);
    }, [])

    return (
        <>
            <div className={cx('header')}>
                <Typography.Title level={3} >Danh sách API</Typography.Title>
                <Space>
                    <Link to='/admin/ladipage'>
                        <Button size="large" icon={<PlusCircleFilled />} >
                            Thêm mới
                        </Button>
                    </Link>
                </Space>
            </div>
            <Filter form={formSearch} onSearch={onChangePage} loading={loading} />
            <ApiTable
                openEdit={onOpenEdit}
                loading={loading}
                pagination={pagination}
                total={total}
                onChangePage={onChangePage}
                apis={apis} />
            <ModalEdit
                onFinish={onSubmitEdit}
                form={formApi}
                selected={selected}
                show={openEdit}
                onCancel={closeModalEdit} />

        </>
    )
};
