import axiosService from "./axiosService"

const imageApi = {
    upload(body) {
        const url = '/img/upload'
        const formData = new FormData();
        formData.append("files", body);
        return axiosService.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    getAll() {
        const url = '/img/files'
        return axiosService.get(url)
    },
    delete(filename) {
        const url = `/img/delete/${filename}`
        return axiosService.get(url)
    },
}

export default imageApi;