import { Button, Col, Form, Row, Select, Space } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { DualAxes } from '@ant-design/plots';
import moment from 'moment';
import { DatePicker } from 'components/';
import { SearchOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { ERROR_OCCUR } from 'constants/messages';
import statisticApi from 'api/statistic';
import productApi from 'api/product';
import { formatVND } from 'utils/';

export const configStructure = {
    xField: 'time',
    yField: ['value', 'count'],
    tooltip: {
        formatter: (datum) => {
            return { name: datum.value ? datum.type : datum.name, value: datum.value ? formatVND(datum.value) : datum.count };
        },
    },
    geometryOptions: [
        {
            geometry: 'column',
            isGroup: true,
            seriesField: 'type',
            columnWidthRatio: 0.4,
        },
        {
            geometry: 'line',
            seriesField: 'name',
            lineStyle: {
                lineWidth: 4,
            },
        },
    ],
};

export const listMonth = [
    {
        value: 1,
        label: 1,
    },
    {
        value: 2,
        label: 2,
    },
    {
        value: 3,
        label: 3,
    },
    {
        value: 4,
        label: 4,
    },
    {
        value: 5,
        label: 5,
    },
    {
        value: 6,
        label: 6,
    },
    {
        value: 7,
        label: 7,
    },
    {
        value: 8,
        label: 8,
    },
    {
        value: 9,
        label: 9,
    },
    {
        value: 10,
        label: 10,
    },
    {
        value: 11,
        label: 11,
    },
    {
        value: 12,
        label: 12,
    },
]

const initialValues = { year: moment(), month: +moment().format('M') }

export default function ColumnChartTab() {
    const [data, setData] = useState([])
    const [listProduct, setListProduct] = useState([])
    const [loading, setLoading] = useState(false)

    const [form] = Form.useForm()
    const year = Form.useWatch('year', form)
    const month = Form.useWatch('month', form)
    const prod_id = Form.useWatch('prd_id', form)

    const totalXptRevenue = useMemo(() => {
        return data.map(item => ({
            time: item.time,
            value: +item.totalXptRevenue,
            type: 'Doanh thu ước tính',
        }))
    }, [data])

    const totalActualRevenue = useMemo(() => {
        return data.map(item => ({
            time: item.time,
            value: +item.totalActualRevenue,
            type: 'Doanh thu thực tế',
        }))
    }, [data])

    const totalXptOrder = useMemo(() => {
        return data.map(item => ({
            time: item.time,
            count: +item.totalXptOrder,
            name: 'Tổng đơn ước tính',
        }))
    }, [data])

    const totalActualOrder = useMemo(() => {
        return data.map(item => ({
            time: item.time,
            count: +item.totalActualOrder,
            name: 'Tổng đơn hoàn thành',
        }))
    }, [data])

    const onSubmit = async (values) => {
        try {
            setLoading(true)
            const res = await statisticApi.getColChart({
                ...values,
                year: moment(values.year).format('YYYY'),
            })
            setData(res.map(item => ({
                ...item,
                totalOrder: +item.totalOrder,
                totalRevenue: +item.totalRevenue
            })))
        } catch (error) {
            console.log("🚀 ~ error:", error)
            toast.error(error?.response?.data || ERROR_OCCUR)
        } finally {
            setLoading(false)
        }
    }

    const fetchAllProduct = async () => {
        try {
            const res = await productApi.search({
                page_size: 1000000
            })
            setListProduct(res.responses)
        } catch (error) {
            console.log("🚀 ~ error:", error)
            toast.error(error?.response?.data || ERROR_OCCUR)
        }
    }

    useEffect(() => {
        if (year) {
            onSubmit(form.getFieldsValue())
        }
    }, [year])

    useEffect(() => {
        onSubmit(form.getFieldsValue())
    }, [month])

    useEffect(() => {
        onSubmit(form.getFieldsValue())
    }, [prod_id])

    useEffect(() => {
        fetchAllProduct()
    }, [])

    return (
        <>
            <Form form={form} onFinish={onSubmit} layout='vertical' initialValues={initialValues}>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item label='Năm' name='year' rules={[{ required: true }]}>
                            <DatePicker style={{ width: '100%' }} placeholder='' picker="year" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label='Tháng' name='month'>
                            <Select
                                allowClear
                                style={{ width: '100%' }}
                                options={listMonth} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label='Sản phẩm' name='prd_id'>
                            <Select
                                showSearch
                                allowClear
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                style={{ width: '100%' }}
                                options={listProduct.map(item => ({ value: item.id, label: item.name }))} />
                        </Form.Item>
                    </Col>
                </Row>
                {/* <Space>
                    <Button loading={loading} type="primary" htmlType='submit' icon={<SearchOutlined />}>Tìm kiếm</Button>
                </Space> */}
            </Form>
            <DualAxes data={[totalXptRevenue.concat(totalActualRevenue), totalXptOrder.concat(totalActualOrder)]} {...configStructure} />
        </>
    )
}