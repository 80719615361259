import { CloudDownloadOutlined, CloudUploadOutlined, FileExcelFilled, PlusCircleFilled, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Card, Form, Space, Typography } from "antd";
import classNames from "classnames/bind";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { OrderTable, Filter, ModalImport } from "./components";
import style from './index.module.scss'
import { ERROR_OCCUR } from "constants/messages";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getListAllProduct } from "features/products/productSlice";
import orderApi from "api/order";
import * as XLSX from "xlsx";
import { getUser } from "utils/index";
import { ROLE } from "constants/index";
import ModalCreate from "./components/modal-create";

const cx = classNames.bind(style)

export default function OrderPage() {
    const dispatch = useDispatch()

    const [formSearch] = Form.useForm()
    const [formOrder] = Form.useForm();
    const [formImport] = Form.useForm();
    const [formCreate] = Form.useForm();

    const [pagination, setPagination] = useState({
        current_page: 1,
        page_size: 10,
    })
    const [total, setTotal] = useState(0)
    const [orders, setOrders] = useState([])
    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState(null)
    const [openCU, setOpenCU] = useState(false)
    const [openImport, setOpenImport] = useState(false)
    const [openDetail, setOpenDetail] = useState(false)

    const onOpenCreate = () => {
        setOpenCU(true)
    }

    const onOpenEdit = async (item) => {
        setSelected(item)
        setOpenCU(true)
    }

    const closeModalCU = () => {
        setOpenCU(false)
        setSelected(null)
        formOrder.resetFields()
    }

    const onOpenDetail = async (item) => {
        setSelected(item)
        setOpenDetail(true)
    }

    const closeModalDetail = () => {
        setOpenDetail(false)
        setSelected(null)
    }

    const onOpenImport = async (item) => {
        setSelected(item)
        setOpenImport(true)
    }

    const closeModalImport = () => {
        setOpenImport(false)
        setSelected(null)
        formImport.resetFields()
    }

    const onChangePage = (page, pageSize) => {
        setPagination({
            ...pagination,
            current_page: page,
            page_size: pageSize,
        })
        onSearch(page, pageSize)
    }

    const onSearch = async (page = pagination.current_page, page_size = pagination.page_size) => {
        try {
            const values = formSearch.getFieldsValue()
            const payload = {
                ...values,
                ...pagination,
                phone_number: values.phone_number || undefined,
                createdAt1: values.createdAt?.[0] ? values.createdAt?.[0].format('YYYY-MM-DD') : '',
                createdAt2: values.createdAt?.[1] ? values.createdAt?.[1].format('YYYY-MM-DD') : '',
                updatedAt1: values.updatedAt?.[0] ? values.updatedAt?.[0].format('YYYY-MM-DD') : '',
                updatedAt2: values.updatedAt?.[1] ? values.updatedAt?.[1].format('YYYY-MM-DD') : '',
                current_page: page - 1,
                page_size,
            }
            delete payload.createdAt;
            delete payload.updatedAt;
            setLoading(true)
            const res = await orderApi.search(payload)
            setOrders(res.responses.map(item => ({
                ...item,
                key: item.id
            })))
            setTotal(res.totalRecord)
        } catch (error) {
            toast.error(error?.response?.data || ERROR_OCCUR)
        } finally {
            setLoading(false)
        }
    }

    const onExport = async () => {
        try {
            setLoading(true)
            const values = formSearch.getFieldsValue()
            const payload = {
                ...values,
                createdAt1: values.createdAt?.[0] ? values.createdAt?.[0].format('YYYY-MM-DD') : '',
                createdAt2: values.createdAt?.[1] ? values.createdAt?.[1].format('YYYY-MM-DD') : '',
                updatedAt1: values.updatedAt?.[0] ? values.updatedAt?.[0].format('YYYY-MM-DD') : '',
                updatedAt2: values.updatedAt?.[1] ? values.updatedAt?.[1].format('YYYY-MM-DD') : '',
                phone_number: values.phone_number || undefined,
            };
            delete payload.createdAt;
            delete payload.updatedAt;
            const res = await orderApi.export(payload)
            const date = moment().format('YYYY/MM/DD_HH:mm:ss')
            const a = document.createElement('a')
            a.href = URL.createObjectURL(res)
            a.download = `DanhSachDonHang_${date}.xlsx`
            a.click()
        } catch (error) {
            toast.error(error?.response?.data || ERROR_OCCUR)
        } finally {
            setLoading(false)
        }
    }

    const onImport = async (values) => {
        try {
            setLoading(true)
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
            reader.onload = async (e) => {
                /* Parse data */
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                if (Array.isArray(data) && data.length >= 1) {
                    let payload = []
                    let error = false
                    data.filter(item => item.length > 0 && item?.[1] && item?.[21]).forEach((item, idx) => {
                        if (idx) {
                            const [
                                dataCode,
                                orderId,
                                ads,
                                date,
                                createdTime,
                                staff,
                                product,
                                waybill,
                                orderDetail,
                                quantity,
                                note,
                                price,
                                fullName,
                                phoneNumber,
                                address,
                                barangayId,
                                province,
                                city,
                                waybill2,
                                shippingDate,
                                status,
                                status2
                            ] = item

                            payload.push({
                                id: orderId,
                                status: status2,
                                carrier: staff,
                                orderProcessor: ads,
                                waybillNumber: waybill,
                                address
                            })
                        }
                    })
                    if (!error && payload.length > 0) {
                        try {
                            await orderApi.update({
                                requests: payload
                            })
                            toast.success(`Nhập liệu thành công ${payload.length - 1} đơn hàng`)
                            onSearch()
                        } catch (error) {
                            toast.error("Nhập liệu lỗi!")
                        }
                    } else {
                        toast.error("Import error!")
                    }

                } else toast.error("Import error!")
                /* Update state */
            };
            if (rABS) reader.readAsBinaryString(values.upload[0].originFileObj);
            else reader.readAsArrayBuffer(values.upload[0].originFileObj);
        } catch (error) {
            toast.error(error?.response?.data || ERROR_OCCUR)
        } finally {
            setLoading(false)
        }
    }

    const onUpdateSingleOrder = async (id, status) => {
        try {
            setLoading(true)
            await orderApi.update({
                requests: [{
                    id,
                    status
                }]
            })
            toast.success(`Cập nhật thành công`)
            onSearch()
        } catch (error) {
            toast.error(error?.response?.data || 'Cập nhật đơn hàng thất bại')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        dispatch(getListAllProduct())
        setTimeout(() => {
            onSearch()
        }, 500);
    }, [])

    return (
        <>
            <div className={cx('header')}>
                <Typography.Title level={3} >Danh sách đơn hàng</Typography.Title>
                {
                    getUser().role.id != ROLE.other &&
                    <Space>
                        <Button size="large" icon={<PlusCircleOutlined />} onClick={onOpenCreate} >
                            Tạo đơn hàng
                        </Button>
                        <Button size="large" icon={<CloudUploadOutlined />} onClick={onOpenImport} >
                            Nhập liệu
                        </Button>
                        <Button size="large" type='primary' icon={<CloudDownloadOutlined />} onClick={onExport} >
                            Xuất Excel
                        </Button>
                    </Space>
                }

            </div>
            <Filter form={formSearch} onSearch={onChangePage} loading={loading} />
            <Card style={{ marginTop: '1rem' }}>
                Tổng đơn hàng: {total}
            </Card>
            <OrderTable
                onSearch={onSearch}
                openEdit={onOpenEdit}
                openDetail={onOpenDetail}
                loading={loading}
                pagination={pagination}
                total={total}
                loading={loading}
                onChangePage={onChangePage}
                onUpdateSingle={onUpdateSingleOrder}
                orders={orders} />
            <ModalImport loading={loading} show={openImport} onFinish={onImport} form={formImport} onCancel={closeModalImport} />
            <ModalCreate onSearchAll={onSearch} loading={loading} show={openCU} form={formCreate} onCancel={closeModalCU} />
            {/* <ModalDetail
                show={openDetail}
                selected={selected}
                onCancel={closeModalDetail}
            /> */}
        </>
    )
};
