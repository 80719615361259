import { Card, Col, Row, Statistic } from "antd";

export default function RevenueCard({ statistic }) {
    return (
        <Row gutter={[16, 16]}>
            <Col span={6}>
                <Card bordered={false}>
                    <Statistic
                        title="Doanh số thực tế"
                        value={statistic?.actualRevenue || 0}
                        valueStyle={{ color: 'rgb(45, 181, 223)' }}
                        suffix="VND"
                    />
                </Card>
            </Col>
            <Col span={6}>
                <Card bordered={false}>
                    <Statistic
                        title="Doanh số ước tính"
                        value={statistic?.estRevenue || 0}
                        valueStyle={{ color: 'rgb(228, 130, 87)' }}
                        suffix="VND"
                    />
                </Card>
            </Col>
            <Col span={6}>
                <Card bordered={false}>
                    <Statistic
                        title="Tổng CPQC"
                        value={statistic?.totalFeeAds || 0}
                        valueStyle={{ color: 'rgb(158, 222, 115)' }}
                        suffix="VND"
                    />
                </Card>
            </Col>
            <Col span={6}>
                <Card bordered={false}>
                    <Statistic
                        title="Tổng số đơn"
                        value={statistic?.totalOrder || 0}
                        valueStyle={{ color: 'rgb(216, 27, 96)' }}
                    />
                </Card>
            </Col>
            <Col span={6}>
                <Card bordered={false}>
                    <Statistic
                        title="Số đơn hoàn thành"
                        value={statistic?.totalOrderDone || 0}
                        valueStyle={{ color: 'rgb(57, 204, 204)' }}
                    />
                </Card>
            </Col>
            <Col span={6}>
                <Card bordered={false}>
                    <Statistic
                        title="%CPQC Thực tế"
                        value={statistic?.actualFeeAds || 0}
                        valueStyle={{ color: '#017e84' }}
                    // suffix="%"
                    />
                </Card>
            </Col>
            <Col span={6}>
                <Card bordered={false}>
                    <Statistic
                        title="%CPQC Ước tính"
                        value={statistic?.estFeeAds || 0}
                        valueStyle={{ color: 'rgb(255, 199, 100)' }}
                    // suffix="%"
                    />
                </Card>
            </Col>
        </Row>
    )
}