import { Image, Layout, Menu } from "antd";
import style from "./index.module.scss";
import classNames from "classnames/bind";
import { BarsOutlined, CloudUploadOutlined, ControlOutlined, DashboardOutlined, FundProjectionScreenOutlined, GlobalOutlined, HddOutlined, PercentageOutlined, PicCenterOutlined, SettingOutlined, ShoppingCartOutlined, ShoppingOutlined, SolutionOutlined, TeamOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getUser } from "utils/index";
import { useMemo } from "react";
import { ROLE } from "constants/index";
import logo from 'assets/images/logo.png'

const cx = classNames.bind(style);

const { Sider } = Layout
export default function SideBar() {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;

    const getItem = (label, key, icon, hidden, children, type) => {
        return {
            key,
            icon,
            children,
            label,
            type,
            hidden
        };
    };

    const items = useMemo(() => {
        return [
            getItem(
                "CÁ NHÂN",
                'PERSONAL',
                null,
                false,
                [
                    getItem(
                        <Link to="/admin/statistic">Thống kê</Link>,
                        "admin/statistic",
                        <DashboardOutlined />
                    ),
                ],
                'group'
            ),
            getItem(
                "SẢN PHẨM",
                "PRODUCTS",
                null,
                false,
                [
                    getItem(
                        <Link to="/admin/products">Danh sách sản phẩm</Link>,
                        "admin/products",
                        <ShoppingOutlined />
                    ),
                    getItem(
                        <Link to="/admin/variants">Quản lý thuộc tính</Link>,
                        "admin/variants",
                        <ControlOutlined />,
                        getUser().role.id == ROLE.other
                    ),
                    getItem(
                        <Link to="/admin/category-product">Quản lý danh mục SP</Link>,
                        "admin/category-product",
                        <BarsOutlined />,
                        getUser().role.id == ROLE.other
                    ),
                    getItem(
                        <Link to="/admin/warehouses">Danh sách kho</Link>,
                        "admin/warehouses",
                        <HddOutlined />
                    ),
                ],
                'group'
            ),
            getItem(
                "THỊ TRƯỜNG",
                'COUNTRY',
                null,
                false,
                [
                    getItem(
                        <Link to="/admin/countries">Danh sách thị trường</Link>,
                        "admin/countries",
                        <GlobalOutlined />
                    ),
                ],
                'group'
            ),
            getItem(
                "ĐƠN HÀNG",
                'ORDER',
                null,
                false,
                [
                    getItem(
                        <Link to="/admin/orders">Danh sách đơn hàng</Link>,
                        "admin/orders",
                        <ShoppingCartOutlined />,
                        getUser().role.id == ROLE.other
                    ),
                    getItem(
                        <Link to="/admin/user-ads">Chi phí quảng cáo</Link>,
                        "admin/user-ads",
                        <FundProjectionScreenOutlined />,
                        getUser().role.id == ROLE.other
                    ),
                    // getItem(
                    //     <Link to="/admin/my-ads">Chi phí QC của tôi</Link>,
                    //     "admin/my-ads",
                    //     <SolutionOutlined />,
                    //     // getUser().role.id != ROLE.other
                    // ),
                    getItem(
                        <Link to="/admin/combos">Chương trình khuyến mãi</Link>,
                        "admin/combos",
                        <PercentageOutlined />,
                        // getUser().role.id != ROLE.other
                    ),
                    getItem(
                        <Link to="/admin/category-ads">Quản lý nền tảng QC</Link>,
                        "admin/category-ads",
                        <BarsOutlined />,
                        getUser().role.id == ROLE.other
                    ),
                    getItem(
                        <Link to="/admin/topics">Danh sách chủ đề</Link>,
                        "admin/topics",
                        <PicCenterOutlined />,
                        getUser().role.id == ROLE.other
                    ),
                ],
                'group'
            ),
            getItem(
                "LADIPAGE API",
                'CONFIG',
                null,
                false,
                [
                    getItem(
                        <Link to="/admin/apis">Danh sách api</Link>,
                        "admin/apis",
                        <CloudUploadOutlined />
                    ),
                    getItem(
                        <Link to="/admin/ladipage">Cấu hình Ladipage</Link>,
                        "admin/ladipage",
                        <SettingOutlined />,
                    ),
                ],
                'group'
            ),
            getItem(
                "NHÂN SỰ",
                'RESOURCE',
                null,
                getUser().role.id == ROLE.other,
                [
                    getItem(
                        <Link to="/admin/users">Danh sách nhân viên</Link>,
                        "admin/users",
                        <TeamOutlined />,
                    ),
                    getItem(
                        <Link to="/admin/teams">Danh sách team</Link>,
                        "admin/teams",
                        <UsergroupAddOutlined />,
                    ),
                ],
                'group'
            ),
        ];
    }, []);

    return (
        <Sider className={cx('sider')} theme='light'>
            <Link to='/' className={cx("logo")}>
                <Image preview={false} src={logo} height={100} style={{ borderRadius: 50 }} />
            </Link>
            <Menu
                mode="inline"
                selectedKeys={[pathname ? pathname.slice(1) : ""]}
                style={{ width: "100%" }}
                items={items.filter(item => !item.hidden)}
            />
        </Sider>
    );
}
