import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import warehouseApi from 'api/warehouse'

export const warehouseSlice = createSlice({
    name: 'warehouse',
    initialState: {
        list: []
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getListWarehouse.fulfilled, (state, action) => {
                state.list = action.payload
            })

    },
})

export const getListWarehouse = createAsyncThunk(
    'warehouse/getListWarehouse',
    async (payload, { dispatch }) => {
        const res = await warehouseApi.getAll()
        return res
    }
)

export const selectListWarehouse = (state) => state.warehouse.list

export default warehouseSlice.reducer
