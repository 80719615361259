import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Space, Table } from "antd";
import comboApi from "api/combo";
import { COMBO_STATUS } from "constants/";
import { comboLadipageColumns } from "features/ladipage/components/Step2";
import { cloneDeep, isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";

const { Option } = Select
export default function ModalEdit({ form, onFinish, selected, show, onCancel }) {
    const [listCombo, setListCombo] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [pagination, setPagination] = useState({
        current_page: 1,
        page_size: 10,
    })
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)

    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys: selectedRows.map(row => row.key),
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows)
        },
    };

    const onChangePage = (page) => {
        setPagination({
            ...pagination,
            current_page: page
        })
    }

    const onSubmit = (values) => {
        onFinish({
            ...values,
            combos: selectedRows.map(row => row.key).join(',')
        })
    }

    const fetchListCombo = useCallback(async () => {
        try {
            setLoading(true)
            const res = await comboApi.search({
                country_id: selected?.country?.id,
                warehouse_id: selected?.warehouse?.id,
                product_id: selected?.mainProduct?.id,
                is_main: true,
                status: 1,
                ...pagination,
                current_page: pagination.current_page - 1
            })
            const listSelected = cloneDeep(selectedRows)
            setListCombo(res.responses.map((item, index) => {
                if (selected.comboSelectedId.includes(item.id) && listSelected.findIndex(selected => selected.id == item.id) === -1) {
                    listSelected.push({
                        key: item.id,
                        ...item
                    })
                }
                return {
                    key: item.id,
                    ...item
                }
            }))
            setSelectedRows(listSelected)
            setTotal(res.totalRecord)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }, [pagination, selected])

    useEffect(() => {
        if (show) {
            form.setFieldsValue({
                status: selected.status
            })
        }
    }, [show])

    useEffect(() => {
        !isEmpty(selected) && fetchListCombo()
    }, [fetchListCombo, selected])

    return (
        <Modal
            open={show}
            onCancel={onCancel}
            title='Cập nhật API'
            footer=''
            width={800}
        >
            <Form form={form} onFinish={onSubmit} layout='vertical' >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label='Trạng thái' name='status' rules={[{ required: true }]}>
                            <Select>
                                {COMBO_STATUS.map(item => (<Option key={item.value} value={item.value} >{item.label}</Option>))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label='Thị trường'>
                            <Input value={selected?.country?.name} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label='Kho'>
                            <Input value={selected?.warehouse?.name} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label='Sản phẩm chính'>
                            <Input value={selected?.mainProduct?.name} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label='Nền tảng QC'>
                            <Input value={selected?.categoryAds?.name} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Table
                            loading={loading}
                            rowSelection={rowSelection}
                            columns={comboLadipageColumns}
                            dataSource={listCombo}
                            pagination={{ current: pagination.current, pageSize: pagination.page_size, total, onChange: onChangePage }} />
                    </Col>
                    <Form.Item>
                        <Space>
                            <Button disabled={selectedRows.length === 0} htmlType="submit" type='primary' size='large'>
                                Cập nhật
                            </Button>
                            <Button size='large' onClick={onCancel}>
                                Huỷ
                            </Button>
                        </Space>
                    </Form.Item>
                </Row>
            </Form >
        </Modal >
    )
}