import { Badge, Button, Divider, Form, Input, Select, Space, Table } from "antd"
import comboApi from "api/combo"
import userApi from "api/user"
import { ROLE } from "constants/"
import { COMBO_STATUS } from "constants/"
import { selectInfo } from "features/users/userSlice"
import { useCallback, useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { numberWithCommas } from "utils/"
import { StepContext } from "../Context"

export const comboLadipageColumns = [
    {
        key: 'topic',
        dataIndex: 'topic',
        title: 'Chủ đề',
        render: (_) => _.name
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: 'Trạng thái',
        render: (_) => _ == 1 ? <Badge status="processing" text={COMBO_STATUS[0].label} /> : <Badge status="error" text={COMBO_STATUS[1].label} />
    },
    {
        key: 'updatedAt',
        dataIndex: 'updatedAt',
        title: 'Ngày cập nhật',
    },
    {
        key: 'name',
        dataIndex: 'name',
        title: 'Tên CTKM',
    },
    {
        key: 'description',
        dataIndex: 'description',
        title: 'Mô tả',
    },
    {
        key: 'price',
        dataIndex: 'price',
        title: 'Giá',
        render: (_, record) => numberWithCommas(Number(_)?.toFixed(2) * record.countries.rate) + ' ' + record.countries.currency
    },
]

export default function Step2({ onFinish, backStep }) {
    const { formStep1, formStep2, curStep } = useContext(StepContext)

    const userInfo = useSelector(selectInfo)

    const [listCombo, setListCombo] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [pagination, setPagination] = useState({
        current_page: 1,
        page_size: 10,
    })
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)
    const [listUser, setListUser] = useState([])

    const rowSelection = {
        selectedRowKeys: selectedRows.map(row => row.key),
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows)
        },
        preserveSelectedRowKeys: true

    };

    const onChangePage = (page) => {
        setPagination({
            ...pagination,
            current_page: page
        })
    }

    const fetchListCombo = useCallback(async () => {
        try {
            setLoading(true)
            const { countryId: country_id, warehouseId: warehouse_id, productId: product_id } = formStep1.getFieldsValue()
            const res = await comboApi.search({
                country_id,
                warehouse_id,
                product_id,
                is_main: true,
                status: 1,
                ...pagination,
                current_page: pagination.current_page - 1
            })
            setListCombo(res.responses.map((item, index) => ({
                key: item.id,
                ...item
            })))
            setTotal(res.totalRecord)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }, [formStep1, pagination])

    const fetchUser = async () => {
        try {
            if (userInfo?.role.id != ROLE.other) {
                const res = await userApi.getAll({
                    role_id: ROLE.other,
                })
                setListUser(res)
            }
        } catch (error) {
            console.log("🚀 ~ error:", error)
        }
    }

    const onSubmit = () => {
        onFinish(selectedRows.map(item => item.id))
    }

    useEffect(() => {
        if (curStep === 1) {
            fetchListCombo()
        } else {
            setSelectedRows([])
        }
    }, [curStep, fetchListCombo, pagination])

    useEffect(() => {
        fetchUser()
    }, [])

    return (
        <>
            <Form form={formStep2} layout='inline' onFinish={onFinish}>
                {
                    userInfo?.role.id != ROLE.other &&
                    <Form.Item label='Người tạo' name='createdUser' rules={[{ required: true }]}>
                        <Select allowClear style={{ width: '150px' }} options={listUser.map(item => ({ value: item.username, label: item.username }))} />
                    </Form.Item>
                }
                <Form.Item label='Thị trường' name='countryName'>
                    <Input disabled />
                </Form.Item>
                <Form.Item label='Kho' name='warehouseName'>
                    <Input disabled />
                </Form.Item>
                <Form.Item label='Sản phẩm chính' name='productName'>
                    <Input disabled />
                </Form.Item>
                {/* <Form.Item label='Thuộc tính' name='variantName'>
                    <Input disabled />
                </Form.Item> */}
                <Form.Item label='Nền tảng' name='categoryAdsName'>
                    <Input disabled />
                </Form.Item>
            </Form>
            <Divider />
            <strong>Chọn các CTKM cần cấu hình</strong>
            <br />
            <Table
                loading={loading}
                rowSelection={rowSelection}
                columns={comboLadipageColumns}
                dataSource={listCombo}
                pagination={{ current: pagination.current, pageSize: pagination.page_size, total, onChange: onChangePage }} />
            <br />
            <Space>
                <Button disabled={selectedRows.length === 0 || listCombo.length === 0} type='primary' onClick={onSubmit}>Tiếp tục</Button>
                <Button onClick={backStep}>Quay lại</Button>
            </Space>
        </>
    )
}