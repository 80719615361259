import axiosService from "./axiosService"

const adsApi = {
    getMyAds() {
        const url = '/ads/list'
        return axiosService.get(url)
    },
    delete(id) {
        const url = `/ads/${id}`
        return axiosService.delete(url)
    },
    createAndUpdate(body) {
        const url = '/ads/update'
        return axiosService.post(url, body)
    },
    getByUser(params) {
        const url = `/ads/by-user`
        return axiosService.get(url, { params })
    },
    export(id) {
        const url = `/ads/export/${id}`
        return axiosService.post(url, { responseType: 'blob' })
    },
}

export default adsApi;