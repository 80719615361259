import axiosService from "./axiosService"

const comboApi = {
    search(params) {
        const url = '/combo/list'
        return axiosService.get(url, { params })
    },
    createAndUpdate(body) {
        const url = '/combo/update'
        return axiosService.post(url, body)
    }
}

export default comboApi;