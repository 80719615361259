import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Select, Space, Upload } from "antd";
import { InputCurrency } from "components/";
import { PRODUCT_STATUS } from "constants/";
import { selectListCountry } from "features/countries/countrySlice";
import { selectListCategory } from "features/products/productSlice";
import { selectListWarehouse } from "features/warehouses/warehouseSlice";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { normFile } from "utils/";
import WarehouseForm from "./WarehouseForm";

const { Option } = Select
export default function ModalCreateUpdate({ form, onFinish, selected, show, onCancel }) {
    const listCategory = useSelector(selectListCategory)
    const listCountry = useSelector(selectListCountry)
    const listWarehouse = useSelector(selectListWarehouse)

    const warehouse = Form.useWatch('warehouse', form);
    const countryId = Form.useWatch('countryId', form);

    const listWarehouseByCountry = useMemo(() => countryId ? listWarehouse.filter(item => item.country.id == countryId) : listWarehouse, [countryId, listWarehouse])

    const dynamicWarehouse = useMemo(() => {
        const listDisabled = []
        warehouse?.map(item => item?.warehouseId && listDisabled.push(item.warehouseId))
        return listWarehouseByCountry.map(item => ({
            ...item,
            disabled: listDisabled.includes(item.id)
        }))
    }, [warehouse, listWarehouseByCountry])

    const onChangeCountry = () => {
        form.setFieldsValue({
            warehouse: []
        })
    }

    useEffect(() => {
        if (show && selected) {
            const { name, img, productCode, categoryProducts, productStatus, productUnit, price, productStockResponses: warehouses } = selected
            form.setFieldsValue({
                name,
                img: [{
                    uid: 'old',
                    status: 'done',
                    name: 'Ảnh sản phẩm',
                    url: process.env.REACT_APP_API_URL + process.env.REACT_APP_IMAGE_PATH + img
                }],
                productCode,
                categoryId: categoryProducts.id,
                productStatus,
                productUnit,
                price,
                countryId: warehouses[0].countries.id,
                warehouse: warehouses.map(item => ({
                    warehouseId: item.warehouse.id,
                    variant: item.productVariantResponses.map(variant => ({
                        variantId: variant.variant.id,
                        quantity: variant.quantity
                    }))
                }))
            })
        }
    }, [show, selected, form])

    useEffect(() => {
        if (Array.isArray(warehouse) && warehouse.length > 0) {
            let newWarehouse = form.getFieldValue('warehouse')

            warehouse.map((item, index) => {
                if (item?.variant && item.variant.length > 0) {
                    newWarehouse[index].quantity = item.variant.reduce((curr, next) => {
                        return curr + (next?.quantity || 0)
                    }, 0)
                } else if (item) {
                    newWarehouse[index].quantity = 0
                }
            })

            form.setFieldsValue({
                warehouse: newWarehouse
            })
        }
    }, [warehouse])

    return (
        <Modal
            open={show}
            onCancel={onCancel}
            title={!selected ? 'Thêm mới sản phẩm' : 'Cập nhật sản phẩm'}
            footer=''
            width={800}
        >
            <Form form={form} onFinish={onFinish} layout='vertical' >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="img"
                            label="Ảnh sản phẩm"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            rules={[
                                { required: true }
                            ]}
                        >
                            <Upload
                                name="imgProduct"
                                listType="picture"
                                accept="image/png, image/jpeg"
                                maxCount={1}>
                                <Button icon={<UploadOutlined />}>Thêm ảnh</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='Danh mục' name='categoryId' rules={[{ required: true }]}>
                            <Select
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={listCategory.map(item => ({ value: item.id, label: item.name }))} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='Tên' name='name' rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='Mã SP' name='productCode' rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='Trạng thái' name='productStatus' rules={[{ required: true }]}>
                            <Select >
                                {PRODUCT_STATUS.map(item => (<Option key={item.value} value={item.value} >{item.label}</Option>))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='Đơn vị' name='productUnit' rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='Giá' name='price' rules={[{ required: true }]}>
                            <InputCurrency />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='Thị trường' name='countryId' rules={[{ required: true }]}>
                            <Select onChange={onChangeCountry} options={listCountry.map(item => ({ value: item.id, label: item.name }))} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <WarehouseForm listWarehouse={dynamicWarehouse} />
                    </Col>
                    <Form.Item>
                        <Space>
                            <Button htmlType="submit" type='primary' size='large'>
                                {selected ? 'Cập nhật' : 'Tạo'}
                            </Button>
                            <Button size='large' onClick={onCancel}>
                                Huỷ
                            </Button>
                        </Space>
                    </Form.Item>
                </Row>
            </Form >
        </Modal >
    )
}