export * from './messages'

export const COMMODITY_USER = 'COMMODITY_USER'
export const COMMODITY_TOKEN = 'COMMODITY_TOKEN'

export const PRODUCT_STATUS = [
    { value: 1, label: 'Đang hoạt động' },
    { value: 0, label: 'Dừng hoạt động' }
]

export const COMBO_STATUS = [
    { value: 1, label: 'Đang hoạt động' },
    { value: 0, label: 'Dừng hoạt động' }
]

export const ORDER_STATUS = [
    { value: "CANCEL", label: "CANCEL" },
    { value: "PRODUCT DUPLICATE", label: "PRODUCT DUPLICATE" },
    { value: "OPP", label: "OPP" },
    { value: "ACCEPTED", label: "ACCEPTED" },
    { value: "IN TRANSIT", label: "IN TRANSIT" },
    { value: "FOR RETURN", label: "FOR RETURN" },
    { value: "RETURNED", label: "RETURNED" },
    { value: "DELIVERING", label: "DELIVERING" },
    { value: "DELIVERED", label: "DELIVERED" },
    { value: "TEST", label: "TEST" },
]

export const EXPORT_STATUS = [
    { value: 1, label: 'Đã xuất' },
    { value: 0, label: 'Chưa xuất' }
]

export const ROLE = {
    super_admin: 1,
    admin: 2,
    accountant: 3,
    other: 4
}

export const VALIDATE_IMAGE = {
    accept: ["image/jpeg", "image/png"]
}