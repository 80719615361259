import { Card, Form, Space, Steps, Typography } from "antd"
import ladiPageApi from "api/ladipage";
import classNames from "classnames/bind"
import { useState } from "react";
import { toast } from "react-toastify";
import { Step1, Step2, Step3 } from "./components";
import { StepContext } from "./Context";
import styles from './index.module.scss'

const cx = classNames.bind(styles)
export default function LadiPage() {
    const [curStep, setCurStep] = useState(0)
    const [formStep1] = Form.useForm()
    const [formStep2] = Form.useForm()
    const [formStep3] = Form.useForm()

    const onFinishStep1 = (values) => {
        formStep2.setFieldsValue(values)
        setCurStep(curStep + 1)
    }

    const backStep = () => {
        setCurStep(curStep - 1)
    }

    const resetStep = () => {
        setCurStep(0)
        formStep1.resetFields()
        formStep2.resetFields()
        formStep3.resetFields()
    }

    const onFinishStep2 = async (combos) => {
        try {
            const { warehouseId, categoryAdsId, createdUser } = formStep1.getFieldsValue()
            const res = await ladiPageApi.createAndUpdate({
                warehouseId,
                categoryAdsId,
                status: 1,
                combos: combos.join(','),
                createdUser: createdUser || undefined,
            })
            formStep3.setFieldsValue({
                url: res.url,
                description: res.description.join('\n')
            })
            setCurStep(curStep + 1)
            toast.success('Cấu hình ladipage thành công')
        } catch (error) {
            toast.error(error?.response?.data)
        }
    }

    const items = [
        {
            title: 'CHỌN SẢN PHẨM VÀ API',
        },
        {
            title: 'CHỌN CTKM',
        },
        {
            title: 'LẤY THÔNG TIN CẤU HÌNH'
        },
    ]

    return (
        <>
            <div className={cx('header')}>
                <Typography.Title level={3} >Cấu hình Ladipage</Typography.Title>
            </div>
            <StepContext.Provider value={{ curStep, formStep1, formStep2, formStep3 }} >
                <Steps current={curStep} labelPlacement="vertical" items={items} />
                <Card>
                    <div hidden={curStep}>
                        <Step1 onFinish={onFinishStep1} />
                    </div>
                    <div hidden={curStep !== 1}>
                        <Step2 backStep={backStep} onFinish={onFinishStep2} />
                    </div>
                    <div hidden={curStep < 2}>
                        <Step3 resetStep={resetStep} />
                    </div>
                </Card>
            </StepContext.Provider>
        </>
    )
}