import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Space } from "antd";
import { selectListCountry } from "features/warehouses/countrySlice";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function ModalCreateUpdate({ form, onFinish, selected, show, onCancel }) {
    const listCountry = useSelector(selectListCountry)

    useEffect(() => {
        if (selected && show) {
            form.setFieldsValue({
                ...selected,
                countryId: selected.country.id
            })
        }
    }, [show, selected])

    return (
        <Modal
            open={show}
            onCancel={onCancel}
            title={!selected ? 'Thêm mới kho' : 'Cập nhật kho'}
            footer=''
            width={800}
        >
            <Form form={form} onFinish={onFinish} layout='vertical'>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label='Tên kho' name='name' rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='Thị trường' name='countryId' rules={[{ required: true }]}>
                            <Select style={{ width: '100%' }} options={listCountry.map(item => ({ value: item.id, label: item.name }))} />

                        </Form.Item>
                    </Col>
                    <Form.Item>
                        <Space>
                            <Button htmlType="submit" type='primary' size='large'>
                                {selected ? 'Cập nhật' : 'Tạo'}
                            </Button>
                            <Button size='large' onClick={onCancel}>
                                Huỷ
                            </Button>
                        </Space>
                    </Form.Item>
                </Row>
            </Form >
        </Modal >
    )
}