import { COMMODITY_TOKEN, COMMODITY_USER } from "constants/"
import moment from "moment"

export const isUserLoggedIn = () => localStorage.getItem(COMMODITY_USER)

export const setToken = (token) => localStorage.setItem(COMMODITY_TOKEN, token)

export const removeToken = () => localStorage.removeItem(COMMODITY_TOKEN)

export const getToken = () => localStorage.getItem(COMMODITY_TOKEN)

export const setUser = (user) => localStorage.setItem(COMMODITY_USER, JSON.stringify(user))

export const getUser = () => isUserLoggedIn() && JSON.parse(localStorage.getItem(COMMODITY_USER))

export const removeUser = () => localStorage.removeItem(COMMODITY_USER)

export const formatVND = (value) => {
    return parseFloat(value).toLocaleString('it-IT', { style: 'currency', currency: 'VND' }).replaceAll('.', ',')
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const checkPhoneNumber = (_, value) => {
    const regex = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g
    if (!value || regex.test(value)) {
        return Promise.resolve();
    }

    return Promise.reject(new Error('Số điện thoại không hợp lệ'));
};

export const normFile = (e) => {
    console.log('Upload event:', e);

    if (Array.isArray(e)) {
        return e;
    }

    if (e?.fileList?.length > 0) {
        let res = e.fileList;
        e.fileList[0].status = "done"

        return res;
    }

    return e?.fileList;
};

export function getDatesInRange(startDateInput, endDateInput) {
    const startDate = moment(startDateInput);
    const endDate = moment(endDateInput);

    const daysCount = endDate.diff(startDate, 'days') + 1;

    const dates = [];
    for (let i = 0; i < daysCount; i++) {
        const date = moment(startDate).add(i, 'days').format('YYYY-MM-DD');
        dates.push(date);
    }

    return dates;
}

export const disabledFutureDate = (current, date) => {
    // Can not select days after today
    return current && date && current >= moment(date).startOf('day').add(1, 'd');
};

export const disabledPassDate = (current, date) => {
    // Can not select days before today
    return current && date && current <= moment(date).endOf('day').subtract(1, 'd');
};