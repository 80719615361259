import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, InputNumber, Row, Select, Space } from "antd";
import classNames from "classnames/bind";
import { useMemo } from "react";
import style from './index.module.scss'

const cx = classNames.bind(style)

export default function ComboForm({ combos, listProduct }) {
    return (
        <Form.List name="combos">
            {(comboFields, { add, remove }, { errors }) => {
                return (
                    <>
                        {comboFields.map((combo) => {
                            const listVariant = combos[combo.key]?.productId ?
                                listProduct.find(item => item.id === combos[combo.key].productId)
                                    ?.productStockResponses[0]
                                    ?.productVariantResponses || [] : []

                            const quantity = combos[combo.key]?.variantId && listVariant && listVariant.find(item => item.variant.id === combos[combo.key]?.variantId)?.quantity

                            return (
                                <div key={combo.key} className={cx('product-stock')}>
                                    <Space align="center">
                                        <Form.Item
                                            label='Sản phẩm'
                                            {...combo}
                                            name={[combo.name, 'productId']}
                                            rules={[{ required: true }]}
                                        >
                                            <Select
                                                allowClear
                                                style={{ minWidth: '200px' }}
                                                placeholder='Chọn sản phẩm'
                                                options={listProduct.map(item => ({ value: item.id, label: item.name, disabled: item.disabled }))} />
                                        </Form.Item>
                                        <Form.Item
                                            label='Thuộc tính'
                                            {...combo}
                                            name={[combo.name, 'variantId']}
                                            rules={[{ required: true }]}
                                        >
                                            <Select
                                                allowClear
                                                style={{ minWidth: '200px' }}
                                                placeholder='Chọn thuộc tính'
                                                options={listVariant.map(item => ({ value: item.variant.id, label: item.variant.name, disabled: item.disabled }))}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            {...combo}
                                            label='Số lượng'

                                            name={[combo.name, 'quantity']}
                                            rules={[{ required: true }]}
                                        >
                                            <InputNumber style={{ width: '200px' }} min={1} max={quantity} placeholder={quantity && `Còn trong kho: ${quantity}`} />
                                        </Form.Item>
                                        <MinusCircleOutlined className={cx('remove-btn')} onClick={() => remove(combo.name)} />
                                    </Space>

                                </div>
                            )
                        })}

                        <Form.Item>
                            <Button disabled={comboFields.length === listProduct?.length - 1} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Thêm sản phẩm kèm theo
                            </Button>
                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </>
                )
            }}
        </Form.List>
    )
}
