import { ClearOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row, Select, Space } from "antd";
import userApi from "api/user";
import { ROLE } from "constants/";
import { COMBO_STATUS } from "constants/";
import { selectInfo } from "features/users/userSlice";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function Filter({ form, onSearch, loading }) {
    const userInfo = useSelector(selectInfo)

    const [listUser, setListUser] = useState([])

    const fetchUser = async () => {
        try {
            if (userInfo?.role.id != ROLE.other) {
                const res = await userApi.getAll()
                setListUser(res)
            } else {
                setListUser([{ id: userInfo.id, username: userInfo.username }])
                form.setFieldsValue({
                    user_id: userInfo.id
                })
            }
        } catch (error) {
            console.log("🚀 ~ error:", error)
        }
    }

    useEffect(() => {
        fetchUser()
    }, [userInfo])

    return (
        <Card>
            <Form form={form} onFinish={() => onSearch(1)} layout='vertical'>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label='Người tạo' name='user_id'>
                            <Select disabled={userInfo?.role.id == ROLE.other} allowClear style={{ width: '100%' }} options={listUser.map(item => ({ value: item.id, label: item.username }))} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='Trạng thái' name='status' >
                            <Select allowClear style={{ width: '100%' }}>
                                {
                                    COMBO_STATUS.map(item => (
                                        <Select.Option key={item.value} value={item.value}>
                                            {item.label}
                                        </Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Space>
                    <Button loading={loading} type="primary" htmlType='submit' icon={<SearchOutlined />}>Tìm kiếm</Button>
                    <Button loading={loading} danger icon={<ClearOutlined />} onClick={() => form.resetFields()}>Xoá bộ lọc</Button>
                </Space>
            </Form>
        </Card >
    )
};
