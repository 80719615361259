import { AuditOutlined, LockOutlined } from "@ant-design/icons";
import { Button, Card, Tabs, Typography } from "antd"
import classNames from "classnames/bind"
import { AccountDetail } from "./components/AccountDetail";
import { ChangePassword } from "./components/ChangePassword";
import style from './index.module.scss'

const cx = classNames.bind(style)
const { TabPane } = Tabs;
export default function AccountPage() {
    return (
        <>
            <Typography.Title level={3} >Hồ sơ cá nhân</Typography.Title>
            <Card>
                <Tabs defaultActiveKey="1">
                    <TabPane
                        tab={
                            <span>
                                <AuditOutlined />
                                Thông tin
                            </span>
                        }
                        key="1"
                    >
                        <AccountDetail />
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                <LockOutlined />
                                Đổi mật khẩu
                            </span>
                        }
                        key="3"
                    >
                        <ChangePassword />
                    </TabPane>
                </Tabs>
            </Card>
        </>
    )
}