import axiosService from "./axiosService"

const variantApi = {
    getAll() {
        const url = '/variant/list'
        return axiosService.get(url)
    },
    delete(id) {
        const url = `/variant/${id}`
        return axiosService.delete(url)
    },
    createAndUpdate(body) {
        const url = '/variant/update'
        return axiosService.post(url, body)
    }
}

export default variantApi;