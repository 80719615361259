import { CopyOutlined } from "@ant-design/icons";
import { Button, Form, Input, Space } from "antd";
import { useContext } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { StepContext } from "../Context";

export default function Step3({ resetStep }) {
    const { formStep3 } = useContext(StepContext)

    const showSuccess = () => {
        toast.success('Copy thành công')
    }

    return (
        <Form form={formStep3} layout='vertical' >
            <Form.Item label="Link API">
                <Space>
                    <Form.Item
                        name="url"
                        noStyle
                    >
                        <Input
                            style={{
                                width: 800,
                            }}
                            disabled
                        />
                    </Form.Item>
                    <CopyToClipboard text={formStep3?.getFieldValue('url')}>
                        <Button onClick={showSuccess} icon={<CopyOutlined />} />
                    </CopyToClipboard>
                </Space>
            </Form.Item>
            <Form.Item label="Giá trị của trường description">
                <Space>
                    <Form.Item
                        name="description"
                        noStyle
                    >
                        <Input.TextArea
                            rows={20}
                            style={{
                                width: 800,
                            }}
                            disabled
                        />
                    </Form.Item>
                    <CopyToClipboard text={formStep3?.getFieldValue('description')}>
                        <Button onClick={showSuccess} icon={<CopyOutlined />} />
                    </CopyToClipboard>
                </Space>
            </Form.Item>
            <Form.Item>
                <Button type='primary' onClick={resetStep}>
                    Tạo thêm
                </Button>
            </Form.Item>
        </Form>
    )
}