import { Button, Col, Form, Input, InputNumber, Modal, Row, Space } from "antd";
import { useEffect } from "react";

export default function ModalCreateUpdate({ form, onFinish, selected, show, onCancel }) {

    useEffect(() => {
        if (selected && show) {
            form.setFieldsValue(selected)
        }
    }, [show, selected])

    return (
        <Modal
            open={show}
            onCancel={onCancel}
            title={!selected ? 'Thêm mới chủ đề' : 'Cập nhật chủ đề'}
            footer=''
            width={800}
        >
            <Form form={form} onFinish={onFinish} layout='vertical'>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label='Tên chủ đề' name='name' rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Form.Item>
                        <Space>
                            <Button htmlType="submit" type='primary' size='large'>
                                {selected ? 'Cập nhật' : 'Tạo'}
                            </Button>
                            <Button size='large' onClick={onCancel}>
                                Huỷ
                            </Button>
                        </Space>
                    </Form.Item>
                </Row>
            </Form >
        </Modal >
    )
}