import axiosService from "./axiosService"

const ladiPageApi = {
    createAndUpdate(body) {
        const url = '/lading-page/update'
        return axiosService.post(url, body)
    },
    search(params) {
        const url = '/lading-page/list'
        return axiosService.get(url, { params })
    }
}

export default ladiPageApi;