import { Table } from "antd";
import { renderColumns } from "features/combos/constants";

export default function ComboTable({
    products,
    loading,
    pagination: { current_page: current, page_size: pageSize },
    total,
    onChangePage,
    openEdit,
    openDetail
}) {
    return (
        <Table
            loading={loading}
            columns={renderColumns(current, pageSize, openEdit, openDetail)}
            dataSource={products}
            pagination={{ current, pageSize, total, onChange: onChangePage }} />
    )
}